import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import {FormattedMessage, useIntl} from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "..";
import useWindowDimensions from "../core/hooks/useWindowDimensions";
import {LOCALES} from "../core/i18n/locales";
import {isElementInViewport, stripPhone} from "../core/utils/utils";

import styles from "../styles/Footer.module.scss"

const Footer: FC = () => {

  const { store, adminStore } = useContext(Context)

  const location = useLocation()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const {formatMessage: f} = useIntl()
  
  const [isVisible, setIsVisible] = useState(false)
  const [footerVisible, setFooterVisible] = useState(false)
  const [isToTopVisible, setIsToTopVisible] = useState(false)
  
  const toTop = () => {
    window.scrollTo({
      behavior: "smooth",
      top: 0
    })
  }

  const navTo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    
    let path = "", nodeName = event.target.nodeName.toLowerCase()

    if (nodeName == "img" || nodeName == "span") {
      path = event.target.parentElement.pathname;
    } else {
      path = event.target.pathname;
    }

    console.log(path, location.pathname, event)

    if (path == location.pathname) {
      return
    }

    store.setIsFaderVisible(true)
    setTimeout(() => {
      store.setIsFaderShown(true)

      setTimeout(() => {
        navigate(path)
      }, 300)
    }, 100)
  }

  useEffect(() => {
    const onScroll = (event: any) => {
      const scrollTop = event.target.scrollTop;
      if (scrollTop >= 80) {
        setIsToTopVisible(true);
      } else {
        setIsToTopVisible(false);
      }

      const ftr_logo = document.getElementById("ftr_logo")
      const ftr_addr = document.getElementById("ftr_addr")
      const ftr_inn = document.getElementById("ftr_inn")

      if (ftr_logo != null && isElementInViewport(ftr_logo)) {
        ftr_logo.style.opacity = "1"
      }
      if (ftr_addr != null && isElementInViewport(ftr_addr)) {
        setTimeout(() => {
          ftr_addr.style.opacity = "1"

          adminStore.footerData.links.forEach((el: any, ix: number) => {
            const ftr_link = document.getElementById(`ftr_link_${ix}`)
            if (ftr_link != null && isElementInViewport(ftr_link)) {
              setTimeout(() => {
                ftr_link.style.opacity = "1"
              }, 100 * ix)
            }
          })
        }, 200)
      }

      adminStore.footerData.socials.forEach((el: any, ix: number) => {
        const ftr_soc = document.getElementById(`ftr_soc_${ix}`)
        if (ftr_soc != null && isElementInViewport(ftr_soc, 0)) {
          setTimeout(() => {
            ftr_soc.style.opacity = "1"
          }, 100 * ix)
        }
      })
      if (ftr_inn != null && isElementInViewport(ftr_inn, 0)) {
        setTimeout(() => {
          ftr_inn.style.opacity = "1"
        }, 400)
      }
      
    }

    // if (width > 768) {
      setTimeout(() => {
        setIsVisible(prev => prev = true)
        onScroll({target: {scrollTop: 0}})
      }, 400)
    // } else {
    //   setIsVisible(prev => prev = true)
    //   setFooterVisible(prev => prev = true)
    // }

    document.getElementById("root")!.addEventListener('scroll', onScroll);

    return () => {
      document.getElementById("root")!.removeEventListener('scroll', onScroll);
    }
  }, [])

  return (
    adminStore.footerData != null ? <>
      {/*<div className={styles.to_top} onClick={() => toTop()} style={{opacity: isToTopVisible ? "1" : "0"}}><i className="far fa-chevron-up"></i></div>*/}
    
      <footer className={styles.footer} id="ftr" style={{
        // opacity: footerVisible ? "1" : "0",
        // bottom: footerVisible ? "0px" : "-15px"
      }}>
        <div className="container" style={{
          transition: "all 0.3s ease",
          opacity: "1"
        }}>
          <div className="row">
            <div className="col-12 col-md-12">
              <div className={styles.logo} id="ftr_logo" style={{opacity: 0}}>
                <a href="/"><img src="/assets/images/logo.svg" /></a>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className={styles.address} id="ftr_addr" style={{opacity: 0}} dangerouslySetInnerHTML={{__html: store.locale == LOCALES.RUSSIAN ? adminStore.footerData.address : adminStore.footerData.address_en}}></div>
            </div>
            <div className="col-12 col-md-6">
              <div className={styles.links}>
                {adminStore.footerData.links.map((link: any, ix: number) => <a href={link.url} id={`ftr_link_${ix}`} style={{opacity: 0}}>{store.locale == LOCALES.RUSSIAN ? link.name : link.name_en}</a>)}
              </div>
            </div>
            <div className="col-12 col-md-12">
              <div className={styles.copys}>
                {adminStore.footerData.socials.map((link: any, ix: number) => <a href={link.url} id={`ftr_soc_${ix}`} style={{opacity: 0}}>{store.locale == LOCALES.RUSSIAN ? link.name : link.name_en}</a>)}
                <p id="ftr_inn" style={{opacity: 0}} dangerouslySetInnerHTML={{__html: store.locale == LOCALES.RUSSIAN ? adminStore.footerData.inn : adminStore.footerData.inn}}></p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </> : <></>
  )
}

export default observer(Footer)
