export enum HomeMenuPage {
  NONE = "",
  FEATURES = "features",
  PRODS = "products",
  PRODS_O = "products_one",
  PRODS_TW = "products_two",
  PRODS_T = "products_three",
  USEFULLS = "usefulls",
  EXPIRIENCE = "expirience"
}

export enum ControllerName {
  USERS = "/users",
  PROJECTS = "/projects",
  CORE = "/core"
}

export const API_URL = "https://api-cs.mainburo.com"

export const IMG_PLACEHOLDER = "/assets/images/placeholder.jpeg"

export type SliderToggleDirection = "next" | "prev";
export enum SliderToggleDir {
  NEXT = "next",
  PREV = "prev"
}

export enum StorageKeys {
  AUTHED = "nx_mainburo_authed",
  USER = "nx_mainburo_user",
  TOKEN = "nx_mainburo_token",

  SETTINGS_HOME = "nx_mainburo_home",
  SETTINGS_CONTACTS = "nx_mainburo_contacts",
  SETTINGS_HSS = "nx_mainburo_hss",
  SETTINGS_TRANS = "nx_mainburo_trans",
  SETTINGS_TRANS_ST = "nx_mainburo_trans_st",
  SETTINGS_FOOTER_DATA = "nx_mainburo_footer_data",
  SETTINGS_ABOUT = "nx_mainburo_about_data",
  SETTINGS_SEO = "nx_mainburo_seo_config",
  SETTINGS_SERVICES = "nx_mainburo_services_config",
  SETTINGS_TEAM = "nx_cs_team",
  SETTINGS_FEATS = "nx_cs_feats",

  PROJECTS = "nx_mainburo_projects"
}