import { DeleteOutlined, EditOutlined, LoadingOutlined, UploadOutlined } from "@ant-design/icons"
import { message, Switch, Upload, UploadProps } from "antd"
import { FC, useContext, useState, useEffect, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { ReactSortable } from "react-sortablejs"
import { Context } from "../../.."
import { ApiService } from "../../../core/api/api"
import { IXTeamItem, IXPartner, IXAboutSettings, IXAboutBlock, ITeamBlock, IArticle, ITeamMan } from "../../../core/models/XAbout"
import { API_URL, IMG_PLACEHOLDER, StorageKeys } from "../../../core/utils/defaults"

import { Editor } from "@tinymce/tinymce-react"

import Dragger from "antd/es/upload/Dragger"
import imageCompression from "browser-image-compression"
import Modal from "../../../components/Modal"

import styles from "../../../styles/AdminManager.module.scss"

const AboutManager: FC = () => {

  const { adminStore, store } = useContext(Context)

  const location = useLocation()
  const navigate = useNavigate()

  const editorRef = useRef(null as any)
  const editorRef2 = useRef(null as any)
  
  const props: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    beforeUpload: async (file, FileList) => {
      setIsDataLoading(prev => prev = true)
      if (file.type.includes("gif") || file.type.includes("webp") || file.type.includes("svg")) {
        return file
      } else {
        const options = { maxSizeMB: 3.0, maxWidth: 3920, useWebWorker: true }
        try {
          const compressedFile = await imageCompression(file, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          return compressedFile;
        } catch (error) {
          console.log(error);
        }
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    }
  }

  const [activeTab, setActiveTab] = useState(1)

  const [aboutActive, setAboutActive] = useState(false)

  const [aboutImage, setAboutImage] = useState("")
  const [aboutText, setAboutText] = useState("")
  const [aboutTextT, setAboutTextT] = useState("")
  const [aboutTextEn, setAboutTextEn] = useState("")
  const [aboutTextTEn, setAboutTextTEn] = useState("")

  const [aboutBlocks, setAboutBlocks] = useState([] as IXAboutBlock[])
  
  const [selectedBlock, setSelectedBlock] = useState(null as any | null)
  const [blockCount, setBlockCount] = useState("")
  const [blockText, setBlockText] = useState("")
  const [isEdtBlockVisible, setIsEdtBlockVisible] = useState(false)
  
  const [isAddTeamVisible, setIsAddTeamVisible] = useState(false)
  const [isEdtTeamVisible, setIsEdtTeamVisible] = useState(false)
  const [isAEdtTeamVisible, setIsAEdtTeamVisible] = useState(false)
  const [isDelTeamVisible, setIsDelTeamVisible] = useState(false)

  const [isAddArticleVisible, setIsAddArticleVisible] = useState(false)
  const [isEdtArticleVisible, setIsEdtArticleVisible] = useState(false)
  const [isDelArticleVisible, setIsDelArticleVisible] = useState(false)

  const [teamState, setTeamState] = useState(false)
  const [articlesState, setArticlesState] = useState(false)
  const [teamBlocks, setTeamBlocks] = useState([] as ITeamBlock[])
  const [teamMans, setTeamMans] = useState([] as ITeamMan[])
  const [articlesList, setArticlesList] = useState([] as IArticle[])

  const [teamType, setTeamType] = useState("one")
  const [teamTtl, setTeamTtl] = useState("")
  const [teamText, setTeamText] = useState("")
  const [teamImages, setTeamImages] = useState([] as string[])
  const [teamSel, setTeamSel] = useState(0)
  const [isEdtTBVisible, setIsEdtTBVisible] = useState(false)
  const [isDelTBVisible, setIsDelTBVisible] = useState(false)

  const [team, setTeam] = useState([] as any[])

  const [articleText, setArticleText] = useState("")
  const [articleLink, setArticleLink] = useState("")
  const [articleImage, setArticleImage] = useState("")
  const [articleSel, setArticleSel] = useState(0)

  const [isDataLoading, setIsDataLoading] = useState(false)
  
  const fetchAboutData = async () => {
    if (adminStore.aboutSettings != null) {
      setAboutActive(adminStore.aboutSettings.status)

      setAboutBlocks(adminStore.aboutSettings.about.blocks)
      setAboutImage(adminStore.aboutSettings.about.image)
      setAboutText(adminStore.aboutSettings.about.text.ru)
      setAboutTextEn(adminStore.aboutSettings.about.text.en)

      setTeamState(adminStore.aboutSettings.team_state)
      setTeamMans(adminStore.aboutSettings.team_mans)
      setTeam(adminStore.team)

      setArticlesState(adminStore.aboutSettings.articles_state)

      setTeamBlocks(adminStore.aboutSettings.team)
      setArticlesList(adminStore.aboutSettings.articles)
      
    } else {
      if (adminStore.aboutLoad < 3) {
        adminStore.setAboutLoad(adminStore.aboutLoad + 1)
        setTimeout(() => {
          fetchAboutData()
        }, 2000)
      } else {
        navigate("/")
      }
    }
  }

  const saveAboutBlock = async () => {
    updateData((props) => {
      let newProps = {...props}
      let newBlocks: any[] = []

      newProps.about.blocks.forEach(block => {
        let blk: any = {...block}
        if (block.id == selectedBlock.id) {
          blk.count = blockCount
          blk.text = blockText
        }
        newBlocks.push(blk)
      })

      newProps.about.blocks = newBlocks

      return newProps
    })

    setIsEdtBlockVisible(false)
  }

  const updateData = async (callback: (props: IXAboutSettings) => IXAboutSettings) => {
    setIsDataLoading(prev => prev = true)

    let props: IXAboutSettings = buildFinalData()
    console.log(props)

    props = callback(props)

    const res = await ApiService.core.updateSetting(2, {
      data: props
    })

    console.log(res)

    adminStore.setAboutSettings(props)
    adminStore.setIsAboutActive(props.status)
    localStorage.setItem(StorageKeys.SETTINGS_ABOUT, JSON.stringify(props))

    fetchAboutData()

    message.success("Данные успешно обновлены!")

    setIsDataLoading(prev => prev = false)
  }

  const addTeamBlock = async () => {
    updateData((props) => {
      let newProps = {...props}
      newProps.team_mans.push({
        id: newProps.team_mans.length + 1,
        name: teamTtl,
        text: teamText,
        image: teamImages[0]
      })
      return newProps
    })
    setIsAddTeamVisible(false)
  }

  const saveTeamMan = async () => {
    updateData((props) => {
      let newProps = {...props}
      let newTeam: any[] = []
      newProps.team_mans.forEach((tm, ix) => {
        if (ix == teamSel) {
          newTeam.push({
            ...tm,
            name: teamTtl,
            text: teamText,
            image: teamImages[0]
          })
        } else {
          newTeam.push(tm)
        }
      })
      newProps.team_mans = newTeam
      return newProps
    })
    setIsEdtTBVisible(false)
  }

  const deleteTeamManBlock = async () => {
    updateData((props) => {
      let newProps = {...props}
      newProps.team_mans = newProps.team_mans.filter((v, ix) => ix != teamSel)
      return newProps
    })
    setIsDelTBVisible(false)
  }

  const addTBlock = async () => {
    updateData((props) => {
      let newProps = {...props}
      let newTeam: any[] = []
      newProps.team = [...newProps.team, {id: newProps.team.length + 1, type: "text", text: teamText}]
      return newProps
    })
    setIsAEdtTeamVisible(false)
  }

  const saveTeamBlock = async () => {
    updateData((props) => {
      let newProps = {...props}
      let newTeam: any[] = []
      newProps.team.forEach((tm, ix) => {
        if (ix == teamSel) {
          newTeam.push({
            ...tm,
            text: teamText,
            images: teamImages
          })
        } else {
          newTeam.push(tm)
        }
      })
      newProps.team = newTeam
      return newProps
    })
    setIsEdtTeamVisible(false)
  }

  const deleteTeamBlock = async () => {
    updateData((props) => {
      let newProps = {...props}
      newProps.team = newProps.team.filter((v, ix) => ix != teamSel)
      return newProps
    })
    setIsDelTeamVisible(false)
  }

  const addArticleBlock = async () => {
    updateData((props) => {
      let newProps = {...props}
      newProps.articles.push({
        id: newProps.articles.length + 1,
        text: articleText,
        image: articleImage,
        url: articleLink
      })
      return newProps
    })
    setIsAddArticleVisible(false)
  }

  const saveArticleBlock = async () => {
    updateData((props) => {
      let newProps = {...props}
      let newTeam: IArticle[] = []
      newProps.articles.forEach((tm, ix) => {
        if (ix == articleSel) {
          newTeam.push({
            ...tm,
            text: articleText,
            image: articleImage,
            url: articleLink
          })
        } else {
          newTeam.push(tm)
        }
      })
      newProps.articles = newTeam
      return newProps
    })
    setIsEdtArticleVisible(false)
  }

  const deleteArticleBlock = async () => {
    updateData((props) => {
      let newProps = {...props}
      newProps.articles = newProps.articles.filter((v, ix) => ix != articleSel)
      return newProps
    })
    setIsDelArticleVisible(false)
  }

  const editArticle = (item: IArticle, ix: number) => {
    setArticleText(item.text)
    setArticleImage(item.image)
    setArticleLink(item.url)
    setArticleSel(ix)
    setIsEdtArticleVisible(true)
  }

  const editTeamBlock = (item: ITeamBlock, ix: number) => {
    setTeamType(item.type)
    if (item.type == "text") {
      setTeamText(item.text!)
    } else {
      setTeamImages(item.images!)
    }
    setTeamSel(ix)
    setIsEdtTeamVisible(true)
  }

  const buildFinalData = (): IXAboutSettings => {
    let newData: IXAboutSettings = {
      status: aboutActive,
      about: {
        blocks: aboutBlocks,
        image: aboutImage,
        text: {
          ru: activeTab == 1 ? editorRef.current.getContent() : aboutText,
          en: ""
        }
      },
      team_state: teamState,
      team: teamBlocks,
      team_mans: teamMans,
      articles_state: articlesState,
      articles: articlesList
    }

    return newData
  }

  const saveAboutData = async () => {
    setIsDataLoading(prev => prev = true)

    let props: IXAboutSettings = buildFinalData()
    console.log(props)

    const res = await ApiService.core.updateSetting(2, {
      data: props
    })

    console.log(res)

    adminStore.setAboutSettings(props)
    adminStore.setIsAboutActive(props.status)
    localStorage.setItem(StorageKeys.SETTINGS_ABOUT, JSON.stringify(props))

    message.success("Данные успешно обновлены!")

    setIsDataLoading(prev => prev = false)
  }

  const getName = (name: string) => {
    if (name == "three") {
      return "Три изображения"
    } else if (name == "two") {
      return "Два изображения"
    } else if (name == "one") {
      return "Одно изображение"
    } else if (name == "text") {
      return "Текстовый блок"
    }
  }

  const isAboutDataChanged = () => {
    if (
      adminStore.aboutSettings != null &&
      (
        aboutActive != adminStore.aboutSettings.status ||
        aboutImage != adminStore.aboutSettings.about.image ||
        aboutText != adminStore.aboutSettings.about.text.ru ||
        aboutTextEn != adminStore.aboutSettings.about.text.en
      )
    ) {
      return true
    } else {
      return false
    }
  }
  
  const closeManager = () => {
    adminStore.setIsManagerVisibleOp(false)
    
    document.body.style.overflowY = 'unset'
    document.getElementsByTagName("html")[0].style.overflowY = 'unset'
    
    setTimeout(() => {
      adminStore.setIsManagerVisible(false)
    }, 300)
  }

  useEffect(() => {
    adminStore.setAboutLoad(0)
    fetchAboutData()
  }, [])

  return (
    <>
      <div className={styles.manager_body__header}>
        <div className={styles.mbh_title}>
          <div>Панель Управления</div>
          <div>Проекты</div>
        </div>
        <div className={styles.mbh_close} onClick={() => closeManager()}><i className="fas fa-xmark"></i></div>
      </div>
      <div className={styles.manager_body__content}>
        <div className="container">
          <div className="row">

            <div className="col-12 col-md-12">
              <div style={{marginTop: "-4px", marginBottom: "30px"}} className={styles.xtabs + " " + styles.minimized + " " + styles[`active${activeTab}`]}>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 1)}>О компании</div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 2)}>Команда</div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 3)}>Новости</div>
              </div>
            </div>

            {activeTab == 3 ? <>
              <div className={"col-12 col-md-12 " + styles.mobbed} style={{marginBottom: "30px", position: "relative"}}>
                
                <div className={styles.grid_block}>
                  <h3 style={{marginBottom: "20px"}}>Раздел "Новости"</h3>

                  <div className={styles.xforms} style={{marginBottom: "24px"}}>
                    <div className={styles.xform_input__sw}>
                      <Switch defaultChecked={articlesState} checked={articlesState} id="trans_st" onChange={(event) => setArticlesState(prev => prev = event)} /> <span style={{marginLeft: "4px"}}>{adminStore.isAboutActive ? "Включен" : "Выключен"}</span>
                    </div>
                  </div>

                  <div className={styles.xactions} style={{top: ""}}>
                    <div className={styles.xactions_item + " " + styles.successs} onClick={(evt) => {
                      setArticleText("")
                      setArticleLink("")
                      setArticleImage(IMG_PLACEHOLDER)
                      setArticleSel(0)
                      setIsAddArticleVisible(true)
                    }}>Добавить новость</div>
                  </div>

                  <ReactSortable className={styles.xgrid} list={articlesList} setList={setArticlesList}>
                    {articlesList.map((item, ix: number) => <div 
                      draggable={true}
                      key={ix}
                      id={`${ix}`}
                      className={styles.images_bl + " " + styles.article}>
                      <div className={styles.images_bl__edit} onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        editArticle(item, ix)
                      }}><EditOutlined rev={null} /></div>
                      <div className={styles.images_bl__del} onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        setArticleSel(ix)
                        setIsDelArticleVisible(true)
                      }}><DeleteOutlined rev={null} /></div>
                      <div className={styles.images_bl__type}>#{item.id}</div>
                      <div className={styles.images_bl__images}>
                        <div style={{backgroundImage: `url(${item.image})`}}></div>
                      </div>
                      <div className={styles.txt}>
                        <div></div>
                        <div>{item.text}</div>
                      </div>
                    </div>)}
                  </ReactSortable>

                  <button className={styles.xbtn} onClick={() => saveAboutData()}>Сохранить</button>

                </div>
              </div>
            </> : <></>}

            {activeTab == 2 ? <>
              <div className={"col-12 col-md-12 " + styles.mobbed} style={{marginBottom: "30px", position: "relative"}}>
                
                <div className={styles.grid_block}>
                  <h3 style={{marginBottom: "20px"}}>Раздел "Команда"</h3>

                  <div className={styles.xforms} style={{marginBottom: "24px"}}>
                    <div className={styles.xform_input__sw}>
                      <Switch defaultChecked={teamState} checked={teamState} id="trans_st" onChange={(event) => setTeamState(prev => prev = event)} /> <span style={{marginLeft: "4px"}}>{adminStore.isAboutActive ? "Включен" : "Выключен"}</span>
                    </div>
                  </div>

                  <div className={styles.xactions} style={{top: ""}}>
                    <div className={styles.xactions_item + " " + styles.successs} onClick={(evt) => {
                      setTeamType("text")
                      setTeamText("")
                      setTeamImages([IMG_PLACEHOLDER])
                      setTeamSel(0)
                      setIsAEdtTeamVisible(true)
                    }}>Добавить текст</div>
                  </div>
                  
                  <ReactSortable className={styles.xgrid} list={teamBlocks} setList={setTeamBlocks}>
                    {teamBlocks.map((item, ix: number) => <div 
                      draggable={true}
                      key={ix}
                      id={`${ix}`}
                      className={styles.images_bl}>
                      <div className={styles.images_bl__edit} onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        editTeamBlock(item, ix)
                      }}><EditOutlined rev={null} /></div>
                      <div className={styles.images_bl__del} onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        setTeamSel(ix)
                        setIsDelTeamVisible(true)
                      }}><DeleteOutlined rev={null} /></div>
                      <div className={styles.images_bl__type}>{getName(item.type)}</div>
                      {item.type != "text" ? <div className={styles.images_bl__images}>
                        {item.images!.map((im: any) => <div style={{backgroundImage: `url(${im})`}}></div>)}
                      </div> : <div className={styles.txt}>
                        <div></div>
                        <div>{item.text}</div>
                      </div>}
                    </div>)}
                  </ReactSortable>

                  <button className={styles.xbtn} onClick={() => saveAboutData()}>Сохранить</button>

                  <div className="row" style={{borderTop: "1px solid #f1f1f1", marginTop: "20px", paddingTop: "20px", position: "relative"}}>
                    
                    <div className={styles.xactions} style={{top: "", width: "fit-content"}}>
                      <div className={styles.xactions_item + " " + styles.successs} onClick={(evt) => {
                        setTeamType("one")
                        setTeamTtl("")
                        setTeamText("")
                        setTeamImages([IMG_PLACEHOLDER])
                        setTeamSel(0)
                        setIsAddTeamVisible(true)
                      }}>Добавить в состав</div>
                    </div>
                  
                    <div className="col-12 col-md-7">
                      <h3>Cостав команды</h3>
                      <div className={styles.xtable_wrap} style={{marginTop: "20px"}}>
                        <div className={styles.xtable}>
                          <div className={styles.xtable_head}>
                            <div>#</div>
                            <div style={{width: "120px", minWidth: "120px"}}>Заголовок</div>
                            <div style={{width: "100%", minWidth: "auto"}}>Текст</div>
                            <div style={{minWidth: "80px", width: "80px"}}>Действия</div>
                          </div>
                          <ReactSortable className={styles.xtable_body} list={teamMans} setList={setTeamMans}>
                            {teamMans.map((item: any, ix) => <div
                              id={`hb_${ix}`}
                              key={`hbk_${ix}`}
                              draggable={true}
                              className={styles.xlist_item}>
                              <div className={styles.xlist_item__id}>{item.id}</div>
                              <div className={styles.xlist_item__name} style={{width: "120px", minWidth: "120px"}} onClick={() => {
                                // Todo: feature editing
                                // editFeature(item, ix)
                                setTeamType("one")
                                setTeamTtl(item.name)
                                setTeamText(item.text)
                                setTeamImages([item.image])
                                setTeamSel(ix)
                                setIsEdtTBVisible(true)
                              }}><span>{item.name}</span></div>
                              <div className={styles.xlist_item__pagename} style={{width: "100%", minWidth: "auto"}}>{item.text}</div>
                              <div className={styles.xlist_item__actions} style={{minWidth: "80px", width: "80px"}}>
                                <button onClick={() => {
                                  // Todo: feature editing
                                  // editFeature(item, ix)
                                  setTeamType("one")
                                  setTeamTtl(item.name)
                                  setTeamText(item.text)
                                  setTeamImages([item.image])
                                  setTeamSel(ix)
                                  setIsEdtTBVisible(true)
                                }}><i className="far fa-pen-to-square"></i></button>
                                <button onClick={() => {
                                  // Todo: feature deleting
                                  // setFeatureSel(ix)
                                  // setIsDeleteFeatureVisible(true)
                                  setTeamSel(ix)
                                  setIsDelTBVisible(true)
                                }}><i className="far fa-trash"></i></button>
                              </div>
                            </div>)}
                          </ReactSortable>
                        </div>
                      </div> 
                    </div>
                  </div>
                  <button className={styles.xbtn} onClick={() => saveAboutData()}>Сохранить</button>


                </div>
              </div>
            </> : <></>}

            {activeTab == 1 ? <>
              <div className={"col-12 col-md-12 " + styles.mobbed} style={{marginBottom: "30px"}}>
                <div className={styles.grid_block}>
                  <h3>Раздел "О компании"</h3>
                  
                  <div className={styles.xforms}>
                    <div className={styles.xform_input__sw}>
                      <Switch defaultChecked={aboutActive} checked={aboutActive} id="trans_st" onChange={(event) => setAboutActive(prev => prev = event)} /> <span style={{marginLeft: "4px"}}>{adminStore.isAboutActive ? "Включен" : "Выключен"}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className={styles.xform} style={{marginTop: "20px"}}>
                        <div className={styles.xform_input} style={{maxWidth: "unset"}}>
                          <label htmlFor="about_text">Изображение</label>
                          
                          <p className={styles.xdesc}>Нажмите или перенесите изображение в эту область</p>

                          <Dragger style={{paddingLeft: "16px", paddingRight: "16px", width: "fit-content"}} {...props} onChange={(info) => {
                            console.log(info)
                            setIsDataLoading(prev => prev = false)

                            const { status } = info.file
                            if (status === 'done') {
                              message.success(`Файл успешно загружен!`);
                              console.log(info.file.response)
                              setAboutImage(info.file.response.urls[0])
                            }
                          }}>
                            <div className={styles.ximg}>
                              <div style={{backgroundImage: `url(${aboutImage})`}}></div>
                            </div>
                          </Dragger>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className={styles.xform} style={{marginTop: "20px"}}>
                        
                        <div className={styles.xform_input} style={{maxWidth: "unset"}}>
                          <label htmlFor="about_text">Описание</label>
                          <Editor
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={aboutText}
                            onEditorChange={(content: string) => {
                              setAboutTextT(prev => prev = content)
                            }}
                            apiKey="ml9lkgxbsm7v5p1rdynza2e1p25k0p8v22ebhmm4w54h3spb"
                            init={{
                              height: 220,
                              menubar: false,
                              language: "ru",
                              plugins: [
                                'autolink', 'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                                'fullscreen','insertdatetime','media','table','help','wordcount', 'code'
                              ],
                              toolbar: 'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | help | code',
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #000;}'
                            }}
                          />
                        </div>
                        {adminStore.transActive ? <div className={styles.xform_input} style={{maxWidth: "unset"}}>
                          <label htmlFor="about_text_en">Описание (англ.)</label>
                          <Editor
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={aboutText}
                            onEditorChange={(content: string) => {
                              setAboutTextTEn(prev => prev = content)
                            }}
                            apiKey="ml9lkgxbsm7v5p1rdynza2e1p25k0p8v22ebhmm4w54h3spb"
                            init={{
                              height: 220,
                              menubar: true,
                              language: "ru",
                              plugins: [
                                'autolink', 'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                                'fullscreen','insertdatetime','media','table','help','wordcount', 'code'
                              ],
                              toolbar: 'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | help | code',
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #000;}'
                            }}
                          />
                        </div> : <></>}

                      </div>
                    </div>
                  </div>
                  
                  <button className={styles.xbtn} onClick={() => saveAboutData()}>Сохранить</button>

                  <div className="row" style={{borderTop: "1px solid #f1f1f1", marginTop: "20px", paddingTop: "20px"}}>
                    <div className="col-12 col-md-7">
                      <h3>Блоки</h3>
                      <div className={styles.xtable_wrap} style={{marginTop: "20px"}}>
                        <div className={styles.xtable}>
                          <div className={styles.xtable_head}>
                            <div>#</div>
                            <div style={{width: "120px", minWidth: "120px"}}>Заголовок</div>
                            <div style={{width: "100%", minWidth: "auto"}}>Текст</div>
                            <div style={{minWidth: "80px", width: "80px"}}>Действия</div>
                          </div>
                          <ReactSortable className={styles.xtable_body} list={aboutBlocks} setList={setAboutBlocks}>
                            {aboutBlocks.map((item: IXAboutBlock, ix) => <div
                              id={`hb_${ix}`}
                              key={`hbk_${ix}`}
                              draggable={true}
                              className={styles.xlist_item}>
                              <div className={styles.xlist_item__id}>{item.id}</div>
                              <div className={styles.xlist_item__name} style={{width: "120px", minWidth: "120px"}} onClick={() => {
                                // Todo: feature editing
                                // editFeature(item, ix)
                                setBlockCount(item.count)
                                setBlockText(item.text)
                                setSelectedBlock(item)
                                setIsEdtBlockVisible(true)
                              }}><span>{item.count}</span></div>
                              <div className={styles.xlist_item__pagename} style={{width: "100%", minWidth: "auto"}}>{item.text}</div>
                              <div className={styles.xlist_item__actions} style={{minWidth: "80px", width: "80px"}}>
                                <button onClick={() => {
                                  // Todo: feature editing
                                  // editFeature(item, ix)
                                  setBlockCount(item.count)
                                  setBlockText(item.text)
                                  setSelectedBlock(item)
                                  setIsEdtBlockVisible(true)
                                }}><i className="far fa-pen-to-square"></i></button>
                                {/* <button onClick={() => {
                                  // Todo: feature deleting
                                  // setFeatureSel(ix)
                                  // setIsDeleteFeatureVisible(true)
                                }}><i className="far fa-trash"></i></button> */}
                              </div>
                            </div>)}
                          </ReactSortable>
                        </div>
                      </div> 
                    </div>
                  </div>

                  <button className={styles.xbtn} onClick={() => saveAboutData()}>Сохранить</button>
                </div>
              </div>
            </> : <></>}

          </div>
        </div>
      </div>

      <Modal
        visible={isEdtBlockVisible}
        title="Изменить блок"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>

              <div className={styles.xform_input}>
                <label htmlFor="">Количество</label>
                <input type="text" placeholder="100" value={blockCount} onChange={(event) => setBlockCount(event.target.value)} />
              </div>

              <div className={styles.xform_input}>
                <label htmlFor="">Текст</label>
                <input type="text" placeholder="..." value={blockText} onChange={(event) => setBlockText(event.target.value)} />
              </div>

            </div>
          </div>
          <div className="modal_action modal_actionx">
            <div>
              <button onClick={() => saveAboutBlock()} style={{marginBottom: "0px"}}>Сохранить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsEdtBlockVisible(false)} />

      <Modal
        visible={isAddArticleVisible}
        title="Добавить новость"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>

              <div className={styles.xform_input + " " + styles.bigly}>
                <label htmlFor="bl_align">Изображения</label>
                <div className={styles.images_list}>
                  <div className={styles.img_item}>
                    <div className={styles.img_item__img}>
                      <Upload {...props} onChange={(info) => {
                        setIsDataLoading(prev => prev = false)
                        const { status } = info.file;
                        if (status === 'done') {
                          message.success(`Файл успешно загружен!`);
                          console.log(info.file.response)
                          
                          setArticleImage(info.file.response.urls[0])
                        }
                      }}><div className={styles.mdp} style={{backgroundImage: `url(${articleImage})`}}><span className={styles.mddp}><UploadOutlined rev={null} /></span></div></Upload>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.xform_input}>
                <label htmlFor="">Текст</label>
                <textarea rows={5} placeholder="Текст" value={articleText} onChange={(event) => setArticleText(event.target.value)}></textarea>
              </div>

              <div className={styles.xform_input}>
                <label htmlFor="">Ссылка</label>
                <input type="text" placeholder="https://..." value={articleLink} onChange={(event) => setArticleLink(event.target.value)} />
              </div>

            </div>
          </div>
          <div className="modal_action modal_actionx">
            <div>
              <button onClick={() => addArticleBlock()} style={{marginBottom: "0px"}}>Добавить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsAddArticleVisible(false)} />

      <Modal
        visible={isEdtArticleVisible}
        title="Изменить новость"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>

              <div className={styles.xform_input + " " + styles.bigly}>
                <label htmlFor="bl_align">Изображения</label>
                <div className={styles.images_list}>
                  <div className={styles.img_item}>
                    <div className={styles.img_item__img}>
                      <Upload {...props} onChange={(info) => {
                        setIsDataLoading(prev => prev = false)
                        const { status } = info.file;
                        if (status === 'done') {
                          message.success(`Файл успешно загружен!`);
                          console.log(info.file.response)
                          
                          setArticleImage(info.file.response.urls[0])
                        }
                      }}><div className={styles.mdp} style={{backgroundImage: `url(${articleImage})`}}><span className={styles.mddp}><UploadOutlined rev={null} /></span></div></Upload>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.xform_input}>
                <label htmlFor="">Текст</label>
                <textarea rows={5} placeholder="Текст" value={articleText} onChange={(event) => setArticleText(event.target.value)}></textarea>
              </div>

              <div className={styles.xform_input}>
                <label htmlFor="">Ссылка</label>
                <input type="text" placeholder="https://..." value={articleLink} onChange={(event) => setArticleLink(event.target.value)} />
              </div>

            </div>
          </div>
          <div className="modal_action modal_actionx">
            <div>
              <button onClick={() => saveArticleBlock()} style={{marginBottom: "0px"}}>Сохранить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsEdtArticleVisible(false)} />

      <Modal
        visible={isAddTeamVisible}
        title="Добавить блок"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
            
              <div className={styles.flexedxx}>
                <div className={styles.xform_input}>
                  <label htmlFor="">Имя</label>
                  <input type="text" placeholder="Текст" value={teamTtl} onChange={(event) => setTeamTtl(event.target.value)} />
                </div>
                <div className={styles.xform_input}>
                  <label htmlFor="">Должность</label>
                  <input type="text" placeholder="Текст" value={teamText} onChange={(event) => setTeamText(event.target.value)} />
                </div>
              </div>

              <div className={styles.xform_input + " " + styles.bigly}>
                <label htmlFor="bl_align">Изображение</label>
                <div className={styles.images_list}>
                  {teamImages.map((item, ixx) => <div className={styles.img_item}>
                    <div className={styles.img_item__img}>
                      <Upload {...props} onChange={(info) => {
                        setIsDataLoading(prev => prev = false)
                        const { status } = info.file;
                        if (status === 'done') {
                          message.success(`Файл успешно загружен!`);
                          console.log(info.file.response)
                          
                          setTeamImages(prev => {
                            let newLst: any[] = []
                            
                            prev.forEach((element, ixxx) => {
                              if (ixxx == ixx) {
                                newLst.push(info.file.response.urls[0])
                              } else {
                                newLst.push(element)
                              }
                            })

                            return prev = newLst
                          })
                        }
                      }}><div className={styles.mdp} style={{backgroundImage: `url(${item})`}}><span className={styles.mddp}><UploadOutlined rev={null} /></span></div></Upload>
                    </div>
                  </div>)}
                </div>
              </div>

            </div>
          </div>
          <div className="modal_action modal_actionx">
            <div>
              <button onClick={() => addTeamBlock()} style={{marginBottom: "0px"}}>Добавить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsAddTeamVisible(false)} />

      <Modal
        visible={isEdtTBVisible}
        title="Изменить блок"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
            
              <div className={styles.flexedxx}>
                <div className={styles.xform_input}>
                  <label htmlFor="">Имя</label>
                  <input type="text" placeholder="Текст" value={teamTtl} onChange={(event) => setTeamTtl(event.target.value)} />
                </div>
                <div className={styles.xform_input}>
                  <label htmlFor="">Должность</label>
                  <input type="text" placeholder="Текст" value={teamText} onChange={(event) => setTeamText(event.target.value)} />
                </div>
              </div>

              <div className={styles.xform_input + " " + styles.bigly}>
                <label htmlFor="bl_align">Изображение</label>
                <div className={styles.images_list}>
                  {teamImages.map((item, ixx) => <div className={styles.img_item}>
                    <div className={styles.img_item__img}>
                      <Upload {...props} onChange={(info) => {
                        setIsDataLoading(prev => prev = false)
                        const { status } = info.file;
                        if (status === 'done') {
                          message.success(`Файл успешно загружен!`);
                          console.log(info.file.response)
                          
                          setTeamImages(prev => {
                            let newLst: any[] = []
                            
                            prev.forEach((element, ixxx) => {
                              if (ixxx == ixx) {
                                newLst.push(info.file.response.urls[0])
                              } else {
                                newLst.push(element)
                              }
                            })

                            return prev = newLst
                          })
                        }
                      }}><div className={styles.mdp} style={{backgroundImage: `url(${item})`}}><span className={styles.mddp}><UploadOutlined rev={null} /></span></div></Upload>
                    </div>
                  </div>)}
                </div>
              </div>

            </div>
          </div>
          <div className="modal_action modal_actionx">
            <div>
              <button onClick={() => saveTeamMan()} style={{marginBottom: "0px"}}>Сохранить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsEdtTBVisible(false)} />

      <Modal
        visible={isAEdtTeamVisible}
        title="Добавить блок"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              
              {teamType == "text" ? <>
                <div className={styles.xform_input}>
                  <label htmlFor="">Текст</label>
                  <textarea rows={5} placeholder="Текст" value={teamText} onChange={(event) => setTeamText(event.target.value)}></textarea>
                </div>
              </> : <></>}

              {teamType != "text" ? <div className={styles.xform_input} style={{display: teamType == "text" ? "none" : "block"}}>
                <label htmlFor="bl_align">Изображения</label>
                <div className={styles.images_list}>
                  {teamImages.map((item, ixx) => <div className={styles.img_item}>
                    <div className={styles.img_item__img}>
                      <Upload {...props} onChange={(info) => {
                        setIsDataLoading(prev => prev = false)
                        const { status } = info.file;
                        if (status === 'done') {
                          message.success(`Файл успешно загружен!`);
                          console.log(info.file.response)
                          
                          setTeamImages(prev => {
                            let newLst: any[] = []
                            
                            prev.forEach((element, ixxx) => {
                              if (ixxx == ixx) {
                                newLst.push(info.file.response.urls[0])
                              } else {
                                newLst.push(element)
                              }
                            })

                            return prev = newLst
                          })
                        }
                      }}><div className={styles.mdp} style={{backgroundImage: `url(${item})`}}><span className={styles.mddp}><UploadOutlined rev={null} /></span></div></Upload>
                    </div>
                  </div>)}
                </div>
              </div> : <></>}
            </div>
          </div>
          <div className="modal_action modal_actionx">
            <div>
              <button onClick={() => addTBlock()} style={{marginBottom: "0px"}}>Добавить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsAEdtTeamVisible(false)} />

      <Modal
        visible={isEdtTeamVisible}
        title="Изменить блок"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              
              {teamType == "text" ? <>
                <div className={styles.xform_input}>
                  <label htmlFor="">Текст</label>
                  <textarea rows={5} placeholder="Текст" value={teamText} onChange={(event) => setTeamText(event.target.value)}></textarea>
                </div>
              </> : <></>}

              {teamType != "text" ? <div className={styles.xform_input} style={{display: teamType == "text" ? "none" : "block"}}>
                <label htmlFor="bl_align">Изображения</label>
                <div className={styles.images_list}>
                  {teamImages.map((item, ixx) => <div className={styles.img_item}>
                    <div className={styles.img_item__img}>
                      <Upload {...props} onChange={(info) => {
                        setIsDataLoading(prev => prev = false)
                        const { status } = info.file;
                        if (status === 'done') {
                          message.success(`Файл успешно загружен!`);
                          console.log(info.file.response)
                          
                          setTeamImages(prev => {
                            let newLst: any[] = []
                            
                            prev.forEach((element, ixxx) => {
                              if (ixxx == ixx) {
                                newLst.push(info.file.response.urls[0])
                              } else {
                                newLst.push(element)
                              }
                            })

                            return prev = newLst
                          })
                        }
                      }}><div className={styles.mdp} style={{backgroundImage: `url(${item})`}}><span className={styles.mddp}><UploadOutlined rev={null} /></span></div></Upload>
                    </div>
                  </div>)}
                </div>
              </div> : <></>}
            </div>
          </div>
          <div className="modal_action modal_actionx">
            <div>
              <button onClick={() => saveTeamBlock()} style={{marginBottom: "0px"}}>Сохранить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsEdtTeamVisible(false)} />

      <Modal
        visible={isDelArticleVisible}
        title="Удалить новость"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить эту новость?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deleteArticleBlock()} style={{marginBottom: "0px"}}>Удалить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsDelArticleVisible(false)} />

      <Modal
        visible={isDelTeamVisible}
        title="Удалить блок"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить этот блок?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deleteTeamBlock()} style={{marginBottom: "0px"}}>Удалить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsDelTeamVisible(false)} />

      <Modal
        visible={isDelTBVisible}
        title="Удалить из состава"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить этот блок?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deleteTeamManBlock()} style={{marginBottom: "0px"}}>Удалить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsDelTBVisible(false)} />

      <Modal
        visible={isDataLoading}
        title="Загрузка..."
        content={<>
          <div className="modal_text">
            <p style={{textAlign: "center", padding: "32px 0"}}><LoadingOutlined rev={null} spin style={{color: "#000", fontSize: "42px"}} /></p>
          </div>
        </>}
        onClose={() => setIsDataLoading(false)} />
    </>
  )
}

export default observer(AboutManager)                    