import { DeleteOutlined, EditOutlined, LoadingOutlined, UploadOutlined } from "@ant-design/icons"
import { message, Switch, Upload, UploadProps } from "antd"
import { FC, useContext, useState, useEffect, useRef } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { ReactSortable } from "react-sortablejs"
import { Context } from "../../.."
import { ApiService } from "../../../core/api/api"
import { IXTeamItem, IXPartner, IXAboutSettings, IXAboutBlock, ITeamBlock, IArticle, ITeamMan } from "../../../core/models/XAbout"
import { API_URL, IMG_PLACEHOLDER, StorageKeys } from "../../../core/utils/defaults"

import { Editor } from "@tinymce/tinymce-react"

import Dragger from "antd/es/upload/Dragger"
import imageCompression from "browser-image-compression"
import Modal from "../../../components/Modal"

import styles from "../../../styles/AdminManager.module.scss"

const FeaturesManager: FC = () => {

  const { adminStore, store } = useContext(Context)

  const location = useLocation()
  const navigate = useNavigate()

  const editorRef = useRef(null as any)
  const editorRef2 = useRef(null as any)
  
  const props: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    beforeUpload: async (file, FileList) => {
      setIsDataLoading(prev => prev = true)
      if (file.type.includes("gif") || file.type.includes("webp") || file.type.includes("svg")) {
        return file
      } else {
        const options = { maxSizeMB: 3.0, maxWidth: 3920, useWebWorker: true }
        try {
          const compressedFile = await imageCompression(file, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          return compressedFile;
        } catch (error) {
          console.log(error);
        }
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    }
  }
  
  const [contentTypeIy, setContentTypeIy] = useState(0)
  const [contentTypeIx, setContentTypeIx] = useState(0)
  const [contentType, setContentType] = useState("one")
  const [contentBlocks, setContentBlocks] = useState([] as any[])

  const [activeTab, setActiveTab] = useState(1)

  const [aboutActive, setAboutActive] = useState(false)

  const [aboutImage, setAboutImage] = useState("")
  const [aboutText, setAboutText] = useState("")
  const [aboutTextT, setAboutTextT] = useState("")
  const [aboutTextEn, setAboutTextEn] = useState("")
  const [aboutTextTEn, setAboutTextTEn] = useState("")

  const [aboutBlocks, setAboutBlocks] = useState([] as IXAboutBlock[])
  
  const [isAddFeatVisible, setIsAddFeatVisible] = useState(false)
  const [isEdtFeatVisible, setIsEdtFeatVisible] = useState(false)
  const [isDelFeatVisible, setIsDelFeatVisible] = useState(false)
  const [isAddBlockVisible, setIsAddBlockVisible] = useState(false)
  const [isEdtBlockVisible, setIsEdtBlockVisible] = useState(false)

  const [teamState, setTeamState] = useState(false)
  const [articlesState, setArticlesState] = useState(false)
  const [teamBlocks, setTeamBlocks] = useState([] as ITeamBlock[])
  const [teamMans, setTeamMans] = useState([] as ITeamMan[])
  const [articlesList, setArticlesList] = useState([] as IArticle[])

  const [teamType, setTeamType] = useState("one")
  const [teamTtl, setTeamTtl] = useState("")
  const [teamText, setTeamText] = useState("")
  const [teamImages, setTeamImages] = useState([] as string[])
  const [teamSel, setTeamSel] = useState(0)

  const [team, setTeam] = useState([] as any[])

  const [articleText, setArticleText] = useState("")
  const [articleLink, setArticleLink] = useState("")
  const [articleImage, setArticleImage] = useState("")
  const [articleSel, setArticleSel] = useState(0)

  const [isDataLoading, setIsDataLoading] = useState(false)

  const [featsItems, setFeatsItems] = useState([] as any[])
  
  const fetchData = async () => {
    if (adminStore.featSettings != null) {
      setAboutActive(adminStore.featSettings.status)
      setFeatsItems(adminStore.featSettings.items)

      setAboutImage(adminStore.featSettings.image)
      setAboutText(adminStore.featSettings.text)
      setAboutTextEn(adminStore.featSettings.text_en)
    } else {
      if (adminStore.aboutLoad < 3) {
        adminStore.setAboutLoad(adminStore.aboutLoad + 1)
        setTimeout(() => {
          fetchData()
        }, 2000)
      } else {
        navigate("/")
      }
    }
  }

  const saveData = async (feats: any | null = null) => {
    setIsDataLoading(prev => prev = true)

    const res = await ApiService.core.updateSetting(9, {
      data: { status: aboutActive, image: aboutImage, text: aboutText, items: feats == null ? featsItems : feats }
    })

    adminStore.setFeatSettings({status: aboutActive, image: aboutImage, text: aboutText, items: feats == null ? featsItems : feats})

    fetchData()

    message.success("Данные успешно обновлены!")

    setIsDataLoading(prev => prev = false)
  }

  const addFeature = () => {
    setTeamTtl("")
    setTeamText("")
    setTeamSel(0)
    setIsAddFeatVisible(true)
  }

  const addFeatureAction = () => {
    setIsAddFeatVisible(false)
    saveData([...featsItems, {id: featsItems.length + 2, title: teamTtl, text: teamText, blocks: []}])
  }

  const editFeature = (item: any, ix: number) => {
    setTeamTtl(item.title)
    setTeamText(item.text)
    setTeamSel(ix)
    setIsEdtFeatVisible(true)
  }

  const editFeatureAction = () => {
    setIsEdtFeatVisible(false)

    let newLst: any = []

    featsItems.forEach((el, ix) => {
      let itm: any = {...el}
      if (ix == teamSel) {
        itm.title = teamTtl
        itm.text = teamText
      }
      newLst.push(itm)
    })

    saveData(newLst)
  }

  const deleteFeature = (ix: number) => {
    setTeamSel(ix)
    setIsDelFeatVisible(true)
  }

  const deleteFeatureAction = () => {
    setIsDelFeatVisible(false)
    saveData([...featsItems.filter((v, ix) => ix != teamSel)])
  }

  const addBlock = (ix: number) => {
    setContentType("one")
    setContentBlocks([""])
    setTeamSel(ix)
    setIsAddBlockVisible(true)
  }

  const addBlockAction = async () => {
    setIsAddBlockVisible(false)

    let newLst: any = []

    featsItems.forEach((el, ix) => {
      let itm: any = {...el}
      if (ix == teamSel) {
        itm.blocks = [...itm.blocks, {id: itm.blocks.length + 1, images: contentBlocks, type: contentType}]
      }
      newLst.push(itm)
    })

    console.log(newLst)

    saveData(newLst)
  }

  const edtBlockAction = async () => {
    setIsEdtBlockVisible(false)

    let newLst: any = []

    featsItems.forEach((feat, ix) => {
      if (contentTypeIx == ix) {
        let itm: any = {...feat}
        let blks: any[] = []
        itm.blocks.forEach((blk: any, iy: number) => {
          let bllk: any = {...blk}
          if (contentTypeIy == iy) {
            bllk = {...bllk, type: contentType, images: contentBlocks}
          }
          blks.push(bllk)
        })
        itm.blocks = blks
        newLst.push(itm)
      } else {
        newLst.push(feat)
      }
    })

    console.log(newLst)

    saveData(newLst)
  }

  const isAboutDataChanged = () => {
    if (
      adminStore.featSettings != null &&
      (
        aboutActive != adminStore.featSettings.status
      )
    ) {
      return true
    } else {
      return false
    }
  }
  
  const closeManager = () => {
    adminStore.setIsManagerVisibleOp(false)
    
    document.body.style.overflowY = 'unset'
    document.getElementsByTagName("html")[0].style.overflowY = 'unset'
    
    setTimeout(() => {
      adminStore.setIsManagerVisible(false)
    }, 300)
  }

  useEffect(() => {
    adminStore.setAboutLoad(0)
    fetchData()
  }, [])

  return (
    <>
      <div className={styles.manager_body__header}>
        <div className={styles.mbh_title}>
          <div>Панель Управления</div>
          <div>Проекты</div>
        </div>
        <div className={styles.mbh_close} onClick={() => closeManager()}><i className="fas fa-xmark"></i></div>
      </div>
      <div className={styles.manager_body__content}>
        <div className="container">
          <div className="row">

            <div className="col-12 col-md-12">
              <div style={{marginTop: "-4px", marginBottom: "30px"}} className={styles.xtabs + " " + styles.minimized + " " + styles[`active${activeTab}`]}>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 1)}>Основное</div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 2)}>Преимущества</div>
              </div>
            </div>

            {activeTab == 2 ? <>
              <div className={"col-12 col-md-12 " + styles.mobbed} style={{marginBottom: "30px"}}>
                <div className={styles.grid_block}>
                  <h3>Список преимуществ</h3>

                  <div className={styles.xactions} style={{top: "0px"}}>
                    <div className={styles.xactions_item} onClick={() => addFeature()}>Добавить преимущество</div>
                  </div>

                  <ReactSortable className={styles.features_xlst} list={featsItems} setList={(nLst: any[]) => {
                    setFeatsItems(prev => prev = nLst)
                  }}>
                    {featsItems.map((featItem, ix) => <div className={styles.fxl_item}>
                      <h3>{featItem.title.replaceAll("<br/>", " ")}</h3>
                      <p>{featItem.text.slice(0, 100)}...</p>

                      <div className={styles.fxl_item__actions}>
                        <button onClick={() => editFeature(featItem, ix)}>Изменить</button>
                        <button onClick={() => deleteFeature(ix)}>Удалить</button>
                        <button onClick={() => addBlock(ix)}>Добавить блок</button>
                      </div>

                      <div className={styles.fxl_item__blk}>Блоки</div>
                      <div>
                        <ReactSortable className={styles.fxl_item__blks} list={featItem.blocks} setList={(newLsx) => {
                          setFeatsItems(prev => {
                            let newLst: any[] = []
                            prev.forEach((el, ixx) => {
                              let itm: any = {...el}
                              if (ixx == ix) {
                                itm.blocks = newLsx
                              }
                              newLst.push(itm)
                            })
                            return prev = newLst
                          })
                        }}>
                          {featItem.blocks.map((blck: any, iy: number) => <div className={styles.fxl_item__blks_block}>
                            <span className={styles.edt} onClick={() => {
                              setContentTypeIy(iy)
                              setContentTypeIx(ix)
                              setContentType(blck.type)
                              setContentBlocks(blck.images)
                              setIsEdtBlockVisible(true)
                            }}><EditOutlined rev={null} /></span>
                            <span className={styles.del} onClick={() => {
                              setFeatsItems(prev => {
                                let newLst: any[] = []

                                prev.forEach((el, ixx) => {
                                  let itm: any = {...el}
                                  if (ixx == ix) {
                                    itm.blocks = itm.blocks.filter((v: any, xix: number) => xix != iy)
                                  }
                                  newLst.push(itm)
                                })

                                saveData(newLst)

                                return prev = newLst
                              })
                            }}><DeleteOutlined rev={null} /></span>
                            {blck.images.map((img: string) => <div style={{backgroundImage: `url(${img})`}}></div>)}
                          </div>)}
                        </ReactSortable>
                      </div>

                      <div className={styles.fxl_item__actions} style={{marginTop: "16px"}}>
                        <button onClick={() => saveData()}>Сохранить</button>
                      </div>
                    </div>)}
                  </ReactSortable>

                </div>
              </div>
            </> : <></>}

            {activeTab == 1 ? <>
              <div className={"col-12 col-md-12 " + styles.mobbed} style={{marginBottom: "30px"}}>
                <div className={styles.grid_block}>
                  <h3>Раздел "Преимущества"</h3>
                  
                  <div className={styles.xforms}>
                    <div className={styles.xform_input__sw}>
                      <Switch defaultChecked={aboutActive} checked={aboutActive} id="trans_st" onChange={(event) => setAboutActive(prev => prev = event)} /> <span style={{marginLeft: "4px"}}>{adminStore.isAboutActive ? "Включен" : "Выключен"}</span>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className={styles.xform} style={{marginTop: "20px"}}>
                        <div className={styles.xform_input} style={{maxWidth: "unset"}}>
                          <label htmlFor="about_text">Изображение</label>
                          
                          <p className={styles.xdesc}>Нажмите или перенесите изображение в эту область</p>

                          <Dragger style={{paddingLeft: "16px", paddingRight: "16px", width: "fit-content"}} {...props} onChange={(info) => {
                            console.log(info)
                            setIsDataLoading(prev => prev = false)

                            const { status } = info.file
                            if (status === 'done') {
                              message.success(`Файл успешно загружен!`);
                              console.log(info.file.response)
                              setAboutImage(info.file.response.urls[0])
                            }
                          }}>
                            <div className={styles.ximg}>
                              <div style={{backgroundImage: `url(${aboutImage})`}}></div>
                            </div>
                          </Dragger>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className={styles.xform} style={{marginTop: "20px"}}>
                        
                        <div className={styles.xform_input} style={{maxWidth: "unset"}}>
                          <label htmlFor="about_text">Описание</label>
                          <textarea rows={7} value={aboutText} onChange={(ev) => setAboutText(ev.target.value)}></textarea>
                        </div>

                      </div>
                    </div>
                  </div>
                  
                  
                  <button className={styles.xbtn} onClick={() => saveData()}>Сохранить</button>

                </div>
              </div>
            </> : <></>}

          </div>
        </div>
      </div>

      <Modal
        visible={isEdtBlockVisible}
        onClose={() => setIsEdtBlockVisible(false)}
        title="Изменить блок"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              
              <div className={styles.xform_input}>
                <label htmlFor="bl_type">Тип блока</label>
                <span className={styles.selc}>
                  <select name="bl_type" id="bl_type" value={contentType} onChange={(event) => {
                    setContentType(prev => prev = event.target.value)
                      
                    if (event.target.value == "three") {
                      setContentBlocks(["", "", ""])
                    } else if (event.target.value == "two") {
                      setContentBlocks(["", ""])
                    } else if (event.target.value == "one") {
                      setContentBlocks([""])
                    }
                  }}>
                    <option value="one">Одно изображение</option>
                    <option value="two">Два изображения</option>
                    <option value="three">Три изображения</option>
                  </select>
                </span>
              </div>

              <div className={styles.xform_input} style={{display: contentType == "text" ? "none" : "block"}}>
                <label htmlFor="bl_align">Изображения</label>
                <ReactSortable list={contentBlocks} setList={(newLst) => setContentBlocks(newLst)} className={styles.images_list}>
                  {contentBlocks.map((item, ixx) => <div className={styles.img_item}>
                    <div className={styles.img_item__img}>
                      <Upload {...props} onChange={(info) => {
                        setIsDataLoading(prev => prev = false)
                        const { status } = info.file;
                        if (status === 'done') {
                          message.success(`Файл успешно загружен!`);
                          console.log(info.file.response)
                          
                          setContentBlocks(prev => {
                            let newLst: any[] = []
                            
                            prev.forEach((element, ixxx) => {
                              if (ixxx == ixx) {
                                newLst.push(info.file.response.urls[0])
                              } else {
                                newLst.push(element)
                              }
                            })

                            return prev = newLst
                          })
                        }
                      }}><div className={styles.mdp} style={{backgroundImage: `url(${item})`}}><UploadOutlined rev={null} /></div></Upload>
                    </div>
                  </div>)}
                </ReactSortable>
              </div>

            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => edtBlockAction()}>Сохранить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isAddBlockVisible}
        onClose={() => setIsAddBlockVisible(false)}
        title="Добавить блок"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              
              <div className={styles.xform_input}>
                <label htmlFor="bl_type">Тип блока</label>
                <span className={styles.selc}>
                  <select name="bl_type" id="bl_type" value={contentType} onChange={(event) => {
                    setContentType(prev => prev = event.target.value)
                      
                    if (event.target.value == "three") {
                      setContentBlocks(["", "", ""])
                    } else if (event.target.value == "two") {
                      setContentBlocks(["", ""])
                    } else if (event.target.value == "one") {
                      setContentBlocks([""])
                    }
                  }}>
                    <option value="one">Одно изображение</option>
                    <option value="two">Два изображения</option>
                    <option value="three">Три изображения</option>
                  </select>
                </span>
              </div>

              <div className={styles.xform_input} style={{display: contentType == "text" ? "none" : "block"}}>
                <label htmlFor="bl_align">Изображения</label>
                <div className={styles.images_list}>
                  {contentBlocks.map((item, ixx) => <div className={styles.img_item}>
                    <div className={styles.img_item__img}>
                      <Upload {...props} onChange={(info) => {
                        setIsDataLoading(prev => prev = false)
                        const { status } = info.file;
                        if (status === 'done') {
                          message.success(`Файл успешно загружен!`);
                          console.log(info.file.response)
                          
                          setContentBlocks(prev => {
                            let newLst: any[] = []
                            
                            prev.forEach((element, ixxx) => {
                              if (ixxx == ixx) {
                                newLst.push(info.file.response.urls[0])
                              } else {
                                newLst.push(element)
                              }
                            })

                            return prev = newLst
                          })
                        }
                      }}><div className={styles.mdp} style={{backgroundImage: `url(${item})`}}><UploadOutlined rev={null} /></div></Upload>
                    </div>
                  </div>)}
                </div>
              </div>

            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => addBlockAction()}>Добавить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isAddFeatVisible}
        onClose={() => setIsAddFeatVisible(false)}
        title="Добавить преимущество"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="feature_title">Заголовок</label>
                <input id="feature_title" type="text" placeholder="" value={teamTtl} onChange={(event) => setTeamTtl(event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="feature_text">Текст</label>
                <textarea id="feature_text" rows={5} placeholder="" value={teamText} onChange={(event) => setTeamText(event.target.value)}></textarea>
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => addFeatureAction()}>Добавить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isEdtFeatVisible}
        onClose={() => setIsEdtFeatVisible(false)}
        title="Изменить преимущество"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="feature_title">Заголовок</label>
                <input id="feature_title" type="text" placeholder="" value={teamTtl} onChange={(event) => setTeamTtl(event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="feature_text">Текст</label>
                <textarea id="feature_text" rows={5} placeholder="" value={teamText} onChange={(event) => setTeamText(event.target.value)}></textarea>
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => editFeatureAction()}>Сохранить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isDelFeatVisible}
        onClose={() => setIsDelFeatVisible(false)}
        title="Удалить преимущество"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить это преимущество?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deleteFeatureAction()}>Удалить</button>
            </div>
          </div>
        </>} />
    </>
  )
}

export default observer(FeaturesManager)                    