import { makeAutoObservable } from "mobx";
import {IXSeoConfig} from "../models/IXSeo";
import {IXServicesConfig} from "../models/IXServices";
import {IXAboutSettings} from "../models/XAbout";
import {IXContacts} from "../models/XContacts";
import {IComplete, IFeature, IHeadBlock, IHomeConfig, IImage} from "../models/XHome";
import {IXProject} from "../models/XProject";

export default class AdminStore {

  footerData: any = {}
  metaData: any = {}
  homeImages: string[] = []
  sliderIndex: number = 0
  sliderInited: boolean = false
  homeSliderSpeed: number = 3000
  interSet: any = null

  translations: any = {}
  transes: any = []
  transActive: boolean = false

  pages: any[] = []
  contacts: IXContacts | null = null

  projects: IXProject[] = []
  team: any[] = []

  dragId: number = 0

  isSettingsInited: boolean = false

  isAboutActive: boolean = true
  aboutLoad: number = 0
  aboutSettings: IXAboutSettings | null = null
  featSettings: any | null = null
  
  homeConfig: null | IHomeConfig = null
  seoConfig: null | IXSeoConfig = null
  servicesConfig: null | IXServicesConfig = null

  isManagerVisible: boolean = false
  isManagerVisibleOp: boolean = false

  headImages: IImage[] = []
  aboutImages: IImage[] = []
  certsImages: IImage[] = []
  blagImages: IImage[] = []
  partnersImages: IImage[] = []
  partnersPostImages: IImage[] = []

  featureImages: IImage[] = []
  compImages: IImage[] = []

  featuresList: IFeature[] = []
  completesList: IComplete[] = []
  headBlocks: IHeadBlock[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setFeatSettings(val: any) {
    this.featSettings = val
  }

  setTeam(val: any) {
    this.team = val
  }

  setHeadBlocks(val: IHeadBlock[]) {
    this.headBlocks = val
  }

  setCompletesList(val: IComplete[]) {
    this.completesList = val
  }

  setFeaturesList(val: IFeature[]) {
    this.featuresList = val
  }

  setCompImages(val: IImage[]) {
    this.compImages = val
  }

  setFeatureImages(val: IImage[]) {
    this.featureImages = val
  }
  
  setPartnersPostImages(val: IImage[]) {
    this.partnersPostImages = val
  }

  setPartnersImages(val: IImage[]) {
    this.partnersImages = val
  }

  setCertsImages(val: IImage[]) {
    this.certsImages = val
  }

  setBlagImages(val: IImage[]) {
    this.blagImages = val
  }

  setAboutImages(val: IImage[]) {
    this.aboutImages = val
  }

  setHeadImages(val: IImage[]) {
    this.headImages = val
  }

  setIsManagerVisible(val: boolean) {
    this.isManagerVisible = val
  }

  setIsManagerVisibleOp(val: boolean) {
    this.isManagerVisibleOp = val
  }

  setHomeConfig(val: any | null) {
    this.homeConfig = val
  }

  setSeoConfig(val: IXSeoConfig | null) {
    this.seoConfig = val
  }

  setAboutLoad(val: number) {
    this.aboutLoad = val
  }

  setAboutSettings(val: IXAboutSettings) {
    this.aboutSettings = val
  }

  setIsAboutActive(val: boolean) {
    this.isAboutActive = val
  }

  setTransActive(val: boolean) {
    this.transActive = val
  }

  setIsSettingsInited(val: boolean) {
    this.isSettingsInited = val
  }

  setFooterData(val: any) {
    this.footerData = val
  }

  setTranses(val: any) {
    this.transes = val
  }

  setTranslations(val: any) {
    this.translations = val
  }

  setInterSet(index: any) {
    this.interSet = index
  }

  setHomeSliderSpeed(index: number) {
    this.homeSliderSpeed = index
  }

  setSliderInited(index: boolean) {
    this.sliderInited = index
  }

  setSliderIndex(index: number) {
    this.sliderIndex = index
  }

  setProjects(val: IXProject[]) {
    this.projects = val
  }

  setDragId(id: number) {
    this.dragId = id
  }

  setContacts(val: IXContacts | null) {
    this.contacts = val
  }

  setHomeImages(val: string[]) {
    this.homeImages = val
  }

  setMetaData(val: any) {
    this.metaData = val
  }

  setPages(val: any[]) {
    this.pages = val
  }

}