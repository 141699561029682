import React from "react";

const Home = React.lazy(() => import("../../pages/Home"))
const About = React.lazy(() => import("../../pages/About"))
const Features = React.lazy(() => import("../../pages/Features"))
const Projects = React.lazy(() => import("../../pages/Projects"))
const ProjectSingle = React.lazy(() => import("../../pages/ProjectSingle"))

const Policy = React.lazy(() => import("../../pages/Policy"))

const AdminAuth = React.lazy(() => import("../../pages/Admin/AdminAuth"))

export interface IRoute {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

export enum RouteNames {
  HOME = '/',
  PROJECTS = '/projects',
  PROJECT_SINGLE = '/projects/:id',
  ABOUT = '/about',
  FEATURES = '/features',

  POLICY = '/policy',

  ADMIN_AUTH = "/admin/auth"
}

export const publicRoutes: IRoute[ ] = [
  { path: RouteNames.HOME, exact: true, component: <Home /> },
  { path: RouteNames.PROJECTS, exact: true, component: <Projects /> },
  { path: RouteNames.PROJECT_SINGLE, exact: true, component: <ProjectSingle /> },
  { path: RouteNames.ABOUT, exact: true, component: <About /> },
  { path: RouteNames.FEATURES, exact: true, component: <Features /> },
  
  { path: RouteNames.POLICY, exact: true, component: <Policy /> },

  { path: RouteNames.ADMIN_AUTH, exact: true, component: <AdminAuth /> }
]
