import { FC } from "react"
import { IXProject } from "../../../../core/models/XProject"

import styles from "./XCard.module.scss"

export interface XCardProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLElement>, HTMLElement> {
  item: IXProject
}

const XCard: FC<XCardProps> = ({
  id, key, draggable, className, onClick, item
}) => {
  return (
    <>
      <div 
        className={styles.xcard + " " + className} 
        onClick={(event) => onClick != undefined ? onClick(event) : false}
        draggable={draggable}
        key={key}
        id={id}>
        <div className={styles.xcard_image} style={{backgroundImage: `url(${item.image != null && item.image != "" ? item.image.split(";")[0] : ""})`}}></div>
        <div className={styles.xcard_data}>
          <div className={styles.xcard_data__title} dangerouslySetInnerHTML={{__html: `${item.title}`}}></div>
          <div className={styles.xcard_data__st} dangerouslySetInnerHTML={{__html: `${item.status_str}<span>${item.year}</span>`}}></div>
        </div>
        <div className={styles.xcard_actions}></div>
      </div>
    </>
  )
}

export default XCard