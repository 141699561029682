import { DeleteOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons"
import { message, Switch, Upload, UploadProps } from "antd"
import {AxiosResponse} from "axios"
import imageCompression from "browser-image-compression"
import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."
import { ApiService } from "../../../core/api/api"
import { IFeature, IComplete, IHeadBlock, IHomeConfig, IImage } from "../../../core/models/XHome"
import { API_URL, StorageKeys } from "../../../core/utils/defaults"
import { ReactSortable } from "react-sortablejs"

import Modal from "../../../components/Modal"

import styles from "../../../styles/AdminManager.module.scss"
import {UploadChangeParam, UploadFile} from "antd/es/upload"

const HomeManager: FC = () => {

  const { store, adminStore } = useContext(Context)
  const location = useLocation()
  
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isDeleteImgVisible, setIsDeleteImgVisible] = useState(false)
  
  const [h1Title, setH1Title] = useState("")
  const [h1TitleEn, setH1TitleEn] = useState("")
  const [headBlocks, setHeadBlocks] = useState([] as IHeadBlock[])

  const [isAddPartnerVisible, setIsAddPartnerVisible] = useState(false)
  const [isEditPartnerVisible, setIsEditPartnerVisible] = useState(false)
  const [isDeletePartnerVisible, setIsDeletePartnerVisible] = useState(false)
  
  const [isAddPartnerPostVisible, setIsAddPartnerPostVisible] = useState(false)
  const [isEditPartnerPostVisible, setIsEditPartnerPostVisible] = useState(false)
  const [isDeletePartnerPostVisible, setIsDeletePartnerPostVisible] = useState(false)
  
  const [isAddBlockVisible, setIsAddBlockVisible] = useState(false)
  const [isEditBlockVisible, setIsEditBlockVisible] = useState(false)
  const [isDeleteBlockVisible, setIsDeleteBlockVisible] = useState(false)
  
  const [isAddFeatureVisible, setIsAddFeatureVisible] = useState(false)
  const [isEditFeatureVisible, setIsEditFeatureVisible] = useState(false)
  const [isDeleteFeatureVisible, setIsDeleteFeatureVisible] = useState(false)
  
  const [isAddCompVisible, setIsAddCompVisible] = useState(false)
  const [isEditCompVisible, setIsEditCompVisible] = useState(false)
  const [isDeleteCompVisible, setIsDeleteCompVisible] = useState(false)
  
  const [isDeleteBlagVisible, setIsDeleteBlagVisible] = useState(false)
  const [isDeleteCertVisible, setIsDeleteCertVisible] = useState(false)
  const [isDeleteAboutVisible, setIsDeleteAboutVisible] = useState(false)
  const [isDeleteHeadVisible, setIsDeleteHeadVisible] = useState(false)
  
  const [featureAlign, setFeatureAlign] = useState("")
  const [featureSel, setFeatureSel] = useState(0)
  const [featureTitle, setFeatureTitle] = useState("")
  const [featureText, setFeatureText] = useState("")
  const [featureImages, setFeatureImages] = useState([] as IImage[])

  const [partnerSel, setPartnerSel] = useState(0)
  const [partnerUrl, setPartnerUrl] = useState("")
  const [partnerLink, setPartnerLink] = useState("")

  const [partnerPostSel, setPartnerPostSel] = useState(0)
  const [partnerPostUrl, setPartnerPostUrl] = useState("")
  const [partnerPostLink, setPartnerPostLink] = useState("")

  const [compImage, setCompImage] = useState("")
  const [compSel, setCompSel] = useState(0)
  const [compTitle, setCompTitle] = useState("")
  const [compText, setCompText] = useState("")

  const [blockSel, setBlockSel] = useState(0)
  const [blockCount, setBlockCount] = useState("")
  const [blockSmall, setBlockSmall] = useState("")
  const [blockText, setBlockText] = useState("")
  const [selectedBlock, setSelectedBlock] = useState(null as null | any)

  const [aboutText, setAboutText] = useState("")
  const [aboutTextEn, setAboutTextEn] = useState("")
  const [aboutImages, setAboutImages] = useState([] as IImage[])

  const [certsActive, setCertsActive] = useState(false)
  const [blagActive, setBlagActive] = useState(false)

  const [blagImages, setBlagImages] = useState([] as IImage[])
  const [certsImages, setCertsImages] = useState([] as IImage[])
  const [partnersImages, setPartnersImages] = useState([] as IImage[])
  const [partnersPostImages, setPartnersPostImages] = useState([] as IImage[])
  
  const [featuresList, setFeaturesList] = useState([] as IFeature[])
  const [completesList, setCompletesList] = useState([] as IComplete[])

  const [hsSpeed, setHSSpeed] = useState("")
  const [images, setImages] = useState([] as IImage[])

  const [activeTab, setActiveTab] = useState(1)
  const [activeTabEd, setActiveTabEd] = useState(1)

  const [selectedImg, setSelectedImg] = useState(0)

  const props: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    beforeUpload: async (file, FileList) => {
      setIsDataLoading(prev => prev = true)
      if (file.type.includes("gif") || file.type.includes("webp") || file.type.includes("svg")) {
        return file
      } else {
        const options = { maxSizeMB: 0.8, maxWidth: 1920, useWebWorker: true }
        try {
          const compressedFile = await imageCompression(file, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          return compressedFile;
        } catch (error) {
          console.log(error);
        }
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    }
  }

  const onImageUploadChange = async (
    info: UploadChangeParam<UploadFile<any>>,
    callback: (url: string) => Promise<any>
  ) => {
    setIsDataLoading(prev => prev = false)

      const { status } = info.file;
      if (status === 'done') {
        message.success(`Файл успешно загружен!`);
        console.log(info.file.response)
        
        await callback(info.file.response.urls[0])

        setTimeout(() => {
          saveData((res) => {
            console.log(res)
          })
        }, 1000)
      }
  }

  const parse = (json: any) => {
    return JSON.parse(JSON.stringify(json))
  }

  const fetchData = () => {
    let homeConfig: IHomeConfig = adminStore.homeConfig ?? null as any
    if (homeConfig != null) {
      adminStore.setHeadImages(homeConfig.head.images)
      adminStore.setAboutImages(homeConfig.about.images)
      adminStore.setCertsImages(homeConfig.certs)
      adminStore.setBlagImages(homeConfig.blag)
      adminStore.setPartnersImages(homeConfig.partners)
      adminStore.setPartnersPostImages(homeConfig.suppliers)

      setCertsActive(prev => prev = homeConfig.certs_state)
      setBlagActive(prev => prev = homeConfig.blag_state)

      setH1Title(prev => prev = homeConfig.head.h1)
      setH1TitleEn(prev => prev = homeConfig.head.h1_en!)
      setHeadBlocks(prev => prev = homeConfig.head.blocks)
      adminStore.setHeadBlocks(homeConfig.head.blocks)
      setImages(prev => prev = [...parse(homeConfig.head.images)])
      setHSSpeed(prev => prev = `${homeConfig.head.slider_delay}`)

      setAboutText(prev => prev = homeConfig.about.text)
      setAboutTextEn(prev => prev = homeConfig.about.text_en!)
      setAboutImages(homeConfig.about.images)

      adminStore.setFeaturesList(homeConfig.features)
      adminStore.setCompletesList(homeConfig.completes)

      setFeaturesList(homeConfig.features)
      setCompletesList(homeConfig.completes)

      setCertsImages(prev => prev = homeConfig.certs)
      setBlagImages(prev => prev = homeConfig.blag)
      setPartnersImages(prev => prev = homeConfig.partners)
      setPartnersPostImages(prev => prev = homeConfig.suppliers)
    }
  }

  useEffect(() => {
    adminStore.setAboutImages(aboutImages)
  }, [aboutImages])

  const saveData = async (callback?: (res: AxiosResponse<any, any>) => void) => {
    setIsDataLoading(true)

    const buildedProps = getBuildedProps()

    console.log("buildedProps", buildedProps)

    const res = await ApiService.core.updateSetting(6, {
      data: buildedProps
    })

    if (callback != null) {
      callback(res)
    }

    adminStore.setHomeConfig(buildedProps)

    fetchData()

    setIsDataLoading(false)

    message.success("Данные успешно обновлены!")
  }

  const getBuildedProps = (): IHomeConfig => {
    return {
      head: {
        images: [...parse(adminStore.headImages)],
        slider_delay: hsSpeed,
        h1: h1Title,
        h1_en: h1TitleEn,
        blocks: [...parse(adminStore.headBlocks)]
      },
      features: [...parse(adminStore.featuresList)],
      completes: [...parse(adminStore.completesList)],
      about: {
        text: aboutText,
        text_en: aboutTextEn,
        images: [...parse(adminStore.aboutImages)],
      },
      certs_state: certsActive,
      blag_state: blagActive,
      certs: [...parse(adminStore.certsImages)],
      blag: [...parse(adminStore.blagImages)],
      partners: [...parse(adminStore.partnersImages)],
      suppliers: [...parse(adminStore.partnersPostImages)]
    }
  }

  const editFeature = (item: IFeature, ix: number) => {
    setFeatureAlign(item.align)
    setFeatureTitle(item.title)
    setFeatureText(item.text)
    setFeatureImages(item.images)
    adminStore.setFeatureImages(item.images)
    setFeatureSel(ix)
    setIsEditFeatureVisible(true)
  }

  const editComp = (item: IComplete, ix: number) => {
    setCompImage(item.image)
    setCompTitle(item.title)
    setCompText(item.text)
    setCompSel(ix)
    setIsEditCompVisible(true)
  }

  const editPartnerPost = (item: any, ix: number) => {
    setPartnerPostUrl(item.url)
    setPartnerPostLink(item.link)
    setPartnerPostSel(ix)
    setIsEditPartnerPostVisible(true)
  }

  const editPartner = (item: any, ix: number) => {
    setPartnerUrl(item.url)
    setPartnerLink(item.link)
    setPartnerSel(ix)
    setIsEditPartnerVisible(true)
  }

  const isSpeedChanged = () => {
    if (
      hsSpeed != `${adminStore.homeConfig!.head.slider_delay}`
    ) {
      return true
    } else {
      return false
    }
  }

  const isH1TitleChanged = () => {
    return h1Title != `${adminStore.homeConfig!.head.h1}`
  }
  
  const closeManager = () => {
    adminStore.setIsManagerVisibleOp(false)
    
    document.body.style.overflowY = 'unset'
    document.getElementsByTagName("html")[0].style.overflowY = 'unset'
    
    setTimeout(() => {
      adminStore.setIsManagerVisible(false)
    }, 300)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className={styles.manager_body__header}>
        <div className={styles.mbh_title}>
          <div>Панель Управления</div>
          <div>Проекты</div>
        </div>
        <div className={styles.mbh_close} onClick={() => closeManager()}><i className="fas fa-xmark"></i></div>
      </div>
      <div className={styles.manager_body__content}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div style={{marginTop: "-4px", marginBottom: "30px"}} className={styles.xtabs + " " + styles.minimized + " " + styles[`active${activeTab}`]}>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 1)}>Верхняя часть</div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 2)}>Преимущества</div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 3)}>Мы выполняем</div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 4)}>О компании</div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 5)}>Сертификаты</div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 6)}>Благ. письма</div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 7)}>Партнеры</div>
              </div>
            </div>

            {activeTab == 7 ? <>
              <div className="row" style={{borderTop: "1px solid #ebebeb", paddingTop: "24px"}}>
                <div className={"col-12 col-md-12 " + styles.mobbed} style={{position: "relative"}}>
                  <div className={styles.setting_block} style={{position: "relative"}}>
                    <h3>Партнеры</h3>

                    <div className={styles.xactions} style={{top: "-10px"}}>
                      <div className={styles.xactions_item + " " + styles.success} onClick={() => {
                        setPartnerUrl("/assets/images/placeholder.jpeg")
                        setPartnerLink("")
                        setIsAddPartnerVisible(true)
                      }}>Добавить партнера</div>
                    </div>

                    <p className={styles.xdesc}>Вы можете менять расположение партнеров.<br/>Для этого зажмите партнера и перенесите на нужное место, взамен другого.</p>

                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className={styles.xtable_wrap} style={{marginTop: "20px"}}>
                          <div className={styles.xtable}>
                            <div className={styles.xtable_head}>
                              <div>#</div>
                              <div>Изображение</div>
                              <div style={{width: "100%", minWidth: "auto"}}>Ссылка</div>
                              <div style={{minWidth: "80px", width: "80px"}}>Действия</div>
                            </div>
                            <ReactSortable className={styles.xtable_body} list={partnersImages} setList={setPartnersImages}>
                              {partnersImages.map((item: any, ix) => <div 
                                id={`complete_item_${ix}`}
                                key={`citem_${ix}`}
                                draggable={true}
                                className={styles.xlist_item}>
                                <div className={styles.xlist_item__id}>{item.id}</div>
                                <div className={styles.xlist_item__imgs}>
                                  <img src={item.url} />
                                </div>
                                <div className={styles.xlist_item__name} style={{width: "100%", minWidth: "auto"}} onClick={() => {
                                  // Todo: feature editing
                                  editPartner(item, ix)
                                }}><span>{item.link}</span></div>
                                <div className={styles.xlist_item__actions} style={{minWidth: "80px", width: "80px"}}>
                                  <button onClick={() => {
                                    // Todo: feature editing
                                    editPartner(item, ix)
                                  }}><i className="far fa-pen-to-square"></i></button>
                                  <button onClick={() => {
                                    // Todo: feature deleting
                                    setPartnerSel(ix)
                                    setIsDeletePartnerVisible(true)
                                  }}><i className="far fa-trash"></i></button>
                                </div>
                              </div>)}
                            </ReactSortable>
                          </div>
                        </div> 
                      </div>
                    </div>

                    <button className={styles.xbtn} onClick={() => {
                      adminStore.setPartnersImages(partnersImages)
                      saveData()
                    }}>Сохранить</button>
                  </div>

                  <div className={styles.setting_block} style={{position: "relative", marginTop: "20px", paddingTop: "20px", borderTop: "1px solid #f1f1f1"}}>
                    <h3>Поставщики</h3>

                    <div className={styles.xactions} style={{top: "10px"}}>
                      <div className={styles.xactions_item + " " + styles.success} onClick={() => {
                        setPartnerUrl("/assets/images/placeholder.jpeg")
                        setPartnerLink("")
                        setIsAddPartnerVisible(true)
                      }}>Добавить поставщика</div>
                    </div>

                    <p className={styles.xdesc}>Вы можете менять расположение поставщиков.<br/>Для этого зажмите поставщика и перенесите на нужное место, взамен другого.</p>

                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className={styles.xtable_wrap} style={{marginTop: "20px"}}>
                          <div className={styles.xtable}>
                            <div className={styles.xtable_head}>
                              <div>#</div>
                              <div>Изображение</div>
                              <div style={{width: "100%", minWidth: "auto"}}>Ссылка</div>
                              <div style={{minWidth: "80px", width: "80px"}}>Действия</div>
                            </div>
                            <ReactSortable className={styles.xtable_body} list={partnersPostImages} setList={setPartnersPostImages}>
                              {partnersPostImages.map((item: any, ix) => <div 
                                id={`complete_item_${ix}`}
                                key={`citem_${ix}`}
                                draggable={true}
                                className={styles.xlist_item}>
                                <div className={styles.xlist_item__id}>{item.id}</div>
                                <div className={styles.xlist_item__imgs}>
                                  <img src={item.url} />
                                </div>
                                <div className={styles.xlist_item__name} style={{width: "100%", minWidth: "auto"}} onClick={() => {
                                  // Todo: feature editing
                                  editPartnerPost(item, ix)
                                }}><span>{item.link}</span></div>
                                <div className={styles.xlist_item__actions} style={{minWidth: "80px", width: "80px"}}>
                                  <button onClick={() => {
                                    // Todo: feature editing
                                    editPartnerPost(item, ix)
                                  }}><i className="far fa-pen-to-square"></i></button>
                                  <button onClick={() => {
                                    // Todo: feature deleting
                                    setPartnerPostSel(ix)
                                    setIsDeletePartnerPostVisible(true)
                                  }}><i className="far fa-trash"></i></button>
                                </div>
                              </div>)}
                            </ReactSortable>
                          </div>
                        </div> 
                      </div>
                    </div>

                    <button className={styles.xbtn} onClick={() => {
                      adminStore.setPartnersImages(partnersImages)
                      saveData()
                    }}>Сохранить</button>
                  </div>
                </div>
              </div>
            </> : <></>}

            {activeTab == 6 ? <>
              <div className="row" style={{borderTop: "1px solid #ebebeb", paddingTop: "24px"}}>
                <div className={"col-12 col-md-12 " + styles.mobbed} style={{position: "relative"}}>
                  <div className={styles.setting_block} style={{position: "relative"}}>
                    <h3>Благодарственные письма</h3>

                    <div className={styles.xforms}>
                      <div className={styles.xform_input__sw}>
                        <Switch defaultChecked={blagActive} checked={blagActive} id="trans_st" onChange={(event) => setBlagActive(prev => prev = event)} /> <span style={{marginLeft: "4px"}}>{blagActive ? "Включен" : "Выключен"}</span>
                      </div>
                    </div>

                    <div className={styles.xactions} style={{top: "-10px"}}>
                      <Upload {...props} onChange={(info) => {
                        onImageUploadChange(info, async (url) => {
                          return await new Promise((resolve) => {
                            adminStore.setBlagImages([...blagImages, {id: blagImages.length + 1, url: url}])
                            setBlagImages(prev => prev = [...blagImages, {id: blagImages.length + 1, url: url}])
                            resolve(true)
                          })
                        })
                      }}><div className={styles.xactions_item + " " + styles.success}>Добавить письмо</div></Upload>
                    </div>

                    <p className={styles.xdesc}>Вы можете менять расположение сертификатов.<br/>Для этого зажмите сертификат и перенесите на нужное место, взамен другого.</p>
                        
                    <ReactSortable className={styles.flexed_imgs} list={blagImages} setList={(newLst) => {
                      setBlagImages(prev => prev = newLst)
                      adminStore.setBlagImages(newLst)
                    }}>
                      {blagImages.map((image, ix) => <div 
                        draggable={true}
                        key={ix}
                        id={`${ix}`}
                        style={{backgroundImage: `url(${image.url})`}}>
                        <DeleteOutlined rev={null} onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()

                          setSelectedImg(ix)
                          setIsDeleteBlagVisible(true)
                        }} />
                      </div>)}
                    </ReactSortable>
                    
                    <button className={styles.xbtn} onClick={() => saveData()}>Сохранить</button>
                  </div>
                </div>
              </div>
            </> : <></>}

            {activeTab == 5 ? <>
              <div className="row" style={{borderTop: "1px solid #ebebeb", paddingTop: "24px"}}>
                <div className={"col-12 col-md-12 " + styles.mobbed} style={{position: "relative"}}>
                  <div className={styles.setting_block} style={{position: "relative"}}>
                    <h3>Сертификаты</h3>

                    <div className={styles.xforms}>
                      <div className={styles.xform_input__sw}>
                        <Switch defaultChecked={certsActive} checked={certsActive} id="trans_st" onChange={(event) => setCertsActive(prev => prev = event)} /> <span style={{marginLeft: "4px"}}>{certsActive ? "Включен" : "Выключен"}</span>
                      </div>
                    </div>

                    <div className={styles.xactions} style={{top: "-10px"}}>
                      <Upload {...props} onChange={(info) => {
                        onImageUploadChange(info, async (url) => {
                          return await new Promise((resolve) => {
                            adminStore.setCertsImages([...certsImages, {id: certsImages.length + 1, url: url}])
                            setCertsImages(prev => prev = [...certsImages, {id: certsImages.length + 1, url: url}])
                            resolve(true)
                          })
                        })
                      }}><div className={styles.xactions_item + " " + styles.success}>Добавить сертификат</div></Upload>
                    </div>

                    <p className={styles.xdesc}>Вы можете менять расположение сертификатов.<br/>Для этого зажмите сертификат и перенесите на нужное место, взамен другого.</p>
                        
                    <ReactSortable className={styles.flexed_imgs} list={certsImages} setList={(newLst) => {
                      setCertsImages(prev => prev = newLst)
                      adminStore.setCertsImages(newLst)
                    }}>
                      {certsImages.map((image, ix) => <div 
                        draggable={true}
                        key={ix}
                        id={`${ix}`}
                        style={{backgroundImage: `url(${image.url})`}}>
                        <DeleteOutlined rev={null} onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()

                          setSelectedImg(ix)
                          setIsDeleteCertVisible(true)
                        }} />
                      </div>)}
                    </ReactSortable>
                    
                    <button className={styles.xbtn} onClick={() => saveData()}>Сохранить</button>
                  </div>
                </div>
              </div>
            </> : <></>}

            {activeTab == 4 ? <>
              <div className="row" style={{borderTop: "1px solid #ebebeb", paddingTop: "24px"}}>
                <div className={"col-12 col-md-5 " + styles.mobbed} style={{marginBottom: "30px"}}>
                  <div className={styles.setting_block}>
                    <h3>О нас</h3>
                    
                    <div className={styles.xform}>
                      <div className={styles.xform_input}>
                        <label htmlFor="about_text">Текст</label>
                        <textarea rows={5} id="about_text" placeholder="Текст" value={aboutText} onChange={(event) => setAboutText(event.target.value)}></textarea>
                      </div>
                      {adminStore.transActive ? <div className={styles.xform_input}>
                        <label htmlFor="about_text_en">Текст (англ.)</label>
                        <textarea rows={5} id="about_text_en" placeholder="Текст (англ.)" value={aboutTextEn} onChange={(event) => setAboutTextEn(event.target.value)}></textarea>
                      </div> : <></>}
                    </div>

                    <button className={styles.xbtn} onClick={() => saveData()}>Сохранить</button>
                  </div>
                </div>
                <div className={"col-12 col-md-7 " + styles.mobbed} style={{position: "relative"}}>
                  <div className={styles.setting_block} style={{position: "relative"}}>
                    <h3>Изображения</h3>

                    <div className={styles.xactions} style={{top: "-10px"}}>
                      <Upload {...props} onChange={(info) => {
                        onImageUploadChange(info, async (url) => {
                          return await new Promise((resolve) => {
                            adminStore.setAboutImages([...aboutImages, {id: aboutImages.length + 1, url: url}])
                            resolve(true)
                          })
                        })
                      }}><div className={styles.xactions_item + " " + styles.success}>Добавить</div></Upload>
                    </div>

                    <p className={styles.xdesc}>Вы можете менять расположение изображений.<br/>Для этого зажмите картинку и перенесите на нужное место, взамен другой.</p>
                        
                    <ReactSortable className={styles.flexed_imgs} list={aboutImages} setList={setAboutImages}>
                      {aboutImages.map((image, ix) => <div 
                        draggable={true}
                        key={ix}
                        id={`${ix}`}
                        style={{backgroundImage: `url(${image.url})`}}>
                        <DeleteOutlined rev={null} onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()

                          setSelectedImg(ix)
                          setIsDeleteAboutVisible(true)
                        }} />
                      </div>)}
                    </ReactSortable>
                    
                    <button className={styles.xbtn} onClick={() => saveData()}>Сохранить</button>
                  </div>
                </div>
              </div>
            </> : <></>}

            {activeTab == 3 ? <>
              <div className="row" style={{borderTop: "1px solid #ebebeb", paddingTop: "24px"}}>
                <div className={"col-12 col-md-12 " + styles.mobbed} style={{position: "relative", paddingRight: "36px"}}>
                  <div className={styles.setting_block} style={{position: "relative"}}>
                    <h3>Мы выполняем</h3>

                    <div className={styles.xactions} style={{top: "-10px"}}>
                      <div className={styles.xactions_item + " " + styles.success} onClick={() => {
                        // Todo: feature adding
                        setCompImage("")
                        setCompTitle("")
                        setCompText("")
                        setCompSel(0)
                        setIsAddCompVisible(true)
                      }}>Добавить вид работы</div>
                    </div>

                    <p className={styles.xdesc}>Вы можете менять расположение блоков.<br/>Для этого зажмите блок и перенесите на нужное место, взамен другого.</p>
                        
                    <div className={styles.xtable_wrap} style={{marginTop: "20px"}}>
                      <div className={styles.xtable}>
                        <div className={styles.xtable_head}>
                          <div>#</div>
                          <div>Изображение</div>
                          <div style={{width: "250px", minWidth: "250px"}}>Заголовок</div>
                          <div style={{width: "100%", minWidth: "auto"}}>Текст</div>
                          <div style={{minWidth: "80px", width: "80px"}}>Действия</div>
                        </div>
                        <ReactSortable className={styles.xtable_body} list={completesList} setList={(newLst) => {
                          setCompletesList(prev => prev = newLst)
                          adminStore.setCompletesList(newLst)
                        }}>
                          {adminStore.completesList.map((item: IComplete, ix) => <div 
                            id={`complete_item_${ix}`}
                            key={`citem_${ix}`}
                            draggable={true}
                            className={styles.xlist_item}>
                            <div className={styles.xlist_item__id}>{item.id}</div>
                            <div className={styles.xlist_item__imgs}>
                              <img src={item.image} />
                            </div>
                            <div className={styles.xlist_item__name} style={{width: "250px", minWidth: "250px"}} onClick={() => {
                              // Todo: feature editing
                                editComp(item, ix)
                            }}><span>{item.title}</span></div>
                            <div className={styles.xlist_item__pagename} style={{width: "100%", minWidth: "auto"}}>{item.text}</div>
                            <div className={styles.xlist_item__actions} style={{minWidth: "80px", width: "80px"}}>
                              <button onClick={() => {
                                // Todo: feature editing
                                editComp(item, ix)
                              }}><i className="far fa-pen-to-square"></i></button>
                              <button onClick={() => {
                                // Todo: feature deleting
                                setCompSel(ix)
                                setIsDeleteCompVisible(true)
                              }}><i className="far fa-trash"></i></button>
                            </div>
                          </div>)}
                        </ReactSortable>
                      </div>
                    </div> 

                    <button className={styles.xbtn} onClick={() => saveData()}>Сохранить</button>
                  </div>
                </div>
              </div>
            </> : <></>}

            {activeTab == 2 ? <>
              <div className="row" style={{borderTop: "1px solid #ebebeb", paddingTop: "24px"}}>
                <div className={"col-12 col-md-12 " + styles.mobbed} style={{position: "relative", paddingRight: "36px"}}>
                  <div className={styles.setting_block} style={{position: "relative"}}>
                    <h3>Список преимуществ</h3>

                    <div className={styles.xactions} style={{top: "-10px"}}>
                      <div className={styles.xactions_item + " " + styles.success} onClick={() => {
                        // Todo: feature adding
                        setFeatureAlign("left")
                        setFeatureTitle("")
                        setFeatureText("")
                        setFeatureImages([])
                        setIsAddFeatureVisible(true)
                      }}>Добавить преимущество</div>
                    </div>

                    <div className={styles.xtable_wrap} style={{marginTop: "20px"}}>
                      <div className={styles.xtable}>
                        <div className={styles.xtable_head}>
                          <div>#</div>
                          <div>Изображения</div>
                          <div style={{width: "250px", minWidth: "250px"}}>Заголовок</div>
                          <div style={{width: "100%", minWidth: "auto"}}>Текст</div>
                          <div style={{minWidth: "80px", width: "80px"}}>Действия</div>
                        </div>
                        <ReactSortable className={styles.xtable_body} list={featuresList} setList={(newLst: IFeature[]) => {
                          setFeaturesList(newLst)
                          adminStore.setFeaturesList(newLst)
                        }}>
                          {adminStore.featuresList.map((item: IFeature, ix) => <div
                            id={`hb_${ix}`}
                            key={`hbk_${ix}`}
                            draggable={true}
                            className={styles.xlist_item}>
                            <div className={styles.xlist_item__id}>{item.id}</div>
                            <div className={styles.xlist_item__imgs}>
                              {item.images.slice(0, 3).map((img) => <img src={img.url} />)}
                            </div>
                            <div className={styles.xlist_item__name} style={{width: "250px", minWidth: "250px"}} onClick={() => {
                              // Todo: feature editing
                              editFeature(item, ix)
                            }}><span>{item.title}</span></div>
                            <div className={styles.xlist_item__pagename} style={{width: "100%", minWidth: "auto"}}>{item.text}</div>
                            <div className={styles.xlist_item__actions} style={{minWidth: "80px", width: "80px"}}>
                              <button onClick={() => {
                                // Todo: feature editing
                              editFeature(item, ix)
                              }}><i className="far fa-pen-to-square"></i></button>
                              <button onClick={() => {
                                // Todo: feature deleting
                                setFeatureSel(ix)
                                setIsDeleteFeatureVisible(true)
                              }}><i className="far fa-trash"></i></button>
                            </div>
                          </div>)}
                        </ReactSortable>
                      </div>
                    </div> 

                    <button className={styles.xbtn} onClick={() => saveData()}>Сохранить</button>
                  </div>
                </div>
              </div>
            </> : <></>}

            {activeTab == 1 ? <>
              <div className="row" style={{borderTop: "1px solid #ebebeb", paddingTop: "24px"}}>
                <div className={"col-12 col-md-8 " + styles.mobbed} style={{position: "relative", paddingRight: "36px"}}>
                  <div className={styles.setting_block} style={{position: "relative"}}>
                    <h3>Изображения</h3>

                    <div className={styles.xactions} style={{top: "-10px"}}>
                      <Upload {...props} onChange={(info) => {
                        onImageUploadChange(info, async (url) => {
                          return await new Promise((resolve) => {
                            adminStore.setHeadImages([...images, {id: images.length + 1, url: url}])
                            resolve(true)
                          })
                        })
                      }}><div className={styles.xactions_item + " " + styles.success}>Добавить изображение</div></Upload>
                    </div>

                    <p className={styles.xdesc}>Вы можете менять расположение изображений.<br/>Для этого зажмите картинку и перенесите на нужное место, взамен другой.</p>
                        
                    <ReactSortable className={styles.flexed_imgs} list={images} setList={setImages}>
                      {images.map((image, ix) => <div 
                        draggable={true}
                        key={ix}
                        id={`${ix}`}
                        style={{backgroundImage: `url(${image.url})`}}>
                        <DeleteOutlined rev={null} onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()

                          setSelectedImg(ix)
                          setIsDeleteHeadVisible(true)
                        }} />
                      </div>)}
                    </ReactSortable>
                  </div>
                </div>
                <div className={"col-12 col-md-4 " + styles.mobbed} style={{marginBottom: "30px"}}>
                  <div className={styles.setting_block}>
                    <h3>Скорость смены</h3>
                    
                    <div className={styles.xform}>
                      <div className={styles.xform_input}>
                        <label htmlFor="hs_speed">Скорость смены слайдов</label>
                        <input type="number" id="hs_speed" placeholder="Скорость смены слайдов" value={hsSpeed} onChange={(event) => setHSSpeed(event.target.value)} />
                      </div>
                    </div>

                    <button className={styles.xbtn} disabled={!isSpeedChanged()} onClick={() => saveData()}>Сохранить</button>
                  </div>
                </div>
              </div>
              <div className="row" style={{borderTop: "1px solid #ebebeb", paddingTop: "24px"}}>
                <div className="col-12 col-md-6">
                  <div className={styles.setting_block}>
                    <h3>Заголовок</h3>
                    
                    <div className={styles.xform}>
                      <div className={styles.xform_input}>
                        <label htmlFor="h1_title">Основной заголовок</label>
                        <textarea style={{maxWidth: "90%"}} rows={3} id="h1_title" placeholder="Основной заголовок" value={h1Title} onChange={(event) => setH1Title(event.target.value)}></textarea>
                      </div>
                    </div>

                    <button className={styles.xbtn} disabled={!isH1TitleChanged()} onClick={() => saveData()}>Сохранить</button>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className={styles.setting_block}>
                    <h3>Блоки</h3>

                    <div className={styles.xactions} style={{top: "-10px"}}>
                      <div className={styles.xactions_item + " " + styles.success} onClick={() => {
                        setBlockSmall("%")
                        setBlockCount("100")
                        setBlockText("")
                        setIsAddBlockVisible(true)
                      }}>Добавить блок</div>
                    </div>

                    <div className={styles.xtable_wrap} style={{marginTop: "20px"}}>
                      <div className={styles.xtable}>
                        <div className={styles.xtable_head}>
                          <div>#</div>
                          <div style={{minWidth: "80px", width: "80px"}}>Кол-во</div>
                          <div>Текст</div>
                          <div style={{minWidth: "80px", width: "80px"}}>Действия</div>
                        </div>
                        <ReactSortable className={styles.xtable_body} list={headBlocks} setList={setHeadBlocks}>
                          {headBlocks.map((item: IHeadBlock, ix) => <div 
                            id={`hb_${ix}`}
                            key={`hbk_${ix}`}
                            draggable={true}
                            className={styles.xlist_item}>
                            <div className={styles.xlist_item__id}>{ix + 1}</div>
                            <div className={styles.xlist_item__name} onClick={() => {
                              setBlockCount(item.count!)
                              setBlockSmall(item.small!)
                              setBlockText(item.text!)
                              setBlockSel(ix)
                              setSelectedBlock(item)
                              setIsEditBlockVisible(true)
                            }} style={{minWidth: "80px", width: "80px"}}><span>{item.count}{item.small}</span></div>
                            <div className={styles.xlist_item__pagename}>{item.text}</div>
                            <div className={styles.xlist_item__actions} style={{minWidth: "80px", width: "80px"}}>
                              <button onClick={() => {
                                setBlockCount(item.count!)
                                setBlockSmall(item.small!)
                                setBlockText(item.text!)
                                setBlockSel(ix)
                                setSelectedBlock(item)
                                setIsEditBlockVisible(true)
                              }}><i className="far fa-pen-to-square"></i></button>
                              <button onClick={() => {
                                setBlockSel(ix)
                                setSelectedBlock(item)
                                setIsDeleteBlockVisible(true)
                              }}><i className="far fa-trash"></i></button>
                            </div>
                          </div>)}
                        </ReactSortable>
                      </div>
                    </div> 

                  </div>
                </div>
              </div>
            </> : <></>}
          </div>
        </div>
      </div>

      <Modal
        visible={isAddBlockVisible}
        onClose={() => setIsAddBlockVisible(false)}
        title="Добавить блок"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="block_count">Число</label>
                <input id="block_count" type="text" placeholder="100" value={blockCount} onChange={(event) => setBlockCount(event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="block_small">Символ</label>
                <input id="block_small" type="text" placeholder="+" value={blockSmall} onChange={(event) => setBlockSmall(event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="block_text">Текст</label>
                <input id="block_text" type="text" placeholder="Текст..." value={blockText} onChange={(event) => setBlockText(event.target.value)} />
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={async () => {
                setIsAddBlockVisible(false)
                adminStore.setHeadBlocks([...adminStore.headBlocks, {
                  id: adminStore.featuresList.length + 1,
                  count: blockCount,
                  small: blockSmall,
                  text: blockText
                }])
                await saveData()
              }}>Добавить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isEditBlockVisible}
        onClose={() => setIsEditBlockVisible(false)}
        title="Изменить блок"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="block_count">Число</label>
                <input id="block_count" type="text" placeholder="100" value={blockCount} onChange={(event) => setBlockCount(event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="block_small">Символ</label>
                <input id="block_small" type="text" placeholder="+" value={blockSmall} onChange={(event) => setBlockSmall(event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="block_text">Текст</label>
                <input id="block_text" type="text" placeholder="Текст..." value={blockText} onChange={(event) => setBlockText(event.target.value)} />
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={async () => {
                adminStore.setHeadBlocks([...adminStore.headBlocks.map((v, ix) => {
                  return ix == blockSel ? {
                    ...v,
                    count: blockCount,
                    small: blockSmall,
                    text: blockText
                  } : {...v}
                })])
                await saveData()
                setIsEditBlockVisible(false)
              }}>Сохранить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isAddFeatureVisible}
        onClose={() => setIsAddFeatureVisible(false)}
        title="Добавить преимущество"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
            <div className={styles.xform_input}>
                <label htmlFor="feature_ttl">Заголовок</label>
                <input id="feature_ttl" type="text" placeholder="Заголовок" value={featureTitle} onChange={(event) => setFeatureTitle(event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="feature_text">Текст</label>
                <input id="feature_text" type="text" placeholder="Текст..." value={featureText} onChange={(event) => setFeatureText(event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="feature_text">Изображения</label>

                <Upload {...props} onChange={(info) => {
                  onImageUploadChange(info, async (url) => {
                    return await new Promise((resolve) => {
                      adminStore.setFeatureImages([...adminStore.featureImages, {id: images.length + 1, url: url}])
                      resolve(true)
                    })
                  })
                }}><div className={styles.xform_input__action} onClick={() => {
                  // setContentBlocks(prev => prev = [...prev, [
                  //   {image: "", image_dark: "", text: "", id: prev.length + 2}
                  // ]])
                }}><PlusOutlined rev={null} /></div></Upload>
                <div>
                  <ReactSortable className={styles.images_list} list={featureImages} setList={setFeatureImages}>
                    {adminStore.featureImages.map((featureImage, ix) => <div className={styles.img_item}>
                      <div className={styles.img_item__img}>
                        <div className={styles.mdp} style={{backgroundImage: `url(${featureImage.url})`}}>
                          <span className={styles.mddp} onClick={() => {
                            adminStore.setFeatureImages(adminStore.featureImages.filter((v, iy) => iy != ix))
                          }}><DeleteOutlined rev={null} /></span>
                        </div>
                      </div>
                    </div>)}
                  </ReactSortable>
                </div>
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={async () => {
                adminStore.setFeaturesList([...adminStore.featuresList, {
                  align: "left",
                  id: adminStore.featuresList.length + 1,
                  title: featureTitle,
                  text: featureText,
                  images: [...parse(adminStore.featureImages)]
                }])
                await saveData()
                setIsAddFeatureVisible(false)
              }}>Добавить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isEditFeatureVisible}
        onClose={() => setIsEditFeatureVisible(false)}
        title="Изменить преимущество"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
            <div className={styles.xform_input}>
                <label htmlFor="feature_ttl">Заголовок</label>
                <input id="feature_ttl" type="text" placeholder="Заголовок" value={featureTitle} onChange={(event) => setFeatureTitle(event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="feature_text">Текст</label>
                <input id="feature_text" type="text" placeholder="Текст..." value={featureText} onChange={(event) => setFeatureText(event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="feature_text">Изображения</label>

                <Upload {...props} onChange={(info) => {
                  onImageUploadChange(info, async (url) => {
                    return await new Promise((resolve) => {
                      adminStore.setFeatureImages([...adminStore.featureImages, {id: images.length + 1, url: url}])
                      resolve(true)
                    })
                  })
                }}><div className={styles.xform_input__action} onClick={() => {
                  // setContentBlocks(prev => prev = [...prev, [
                  //   {image: "", image_dark: "", text: "", id: prev.length + 2}
                  // ]])
                }}><PlusOutlined rev={null} /></div></Upload>
                <div>
                  <ReactSortable className={styles.images_list} list={featureImages} setList={setFeatureImages}>
                    {adminStore.featureImages.map((featureImage, ix) => <div className={styles.img_item}>
                      <div className={styles.img_item__img}>
                        <div className={styles.mdp} style={{backgroundImage: `url(${featureImage.url})`}}>
                          <span className={styles.mddp} onClick={() => {
                            adminStore.setFeatureImages(adminStore.featureImages.filter((v, iy) => iy != ix))
                          }}><DeleteOutlined rev={null} /></span>
                        </div>
                      </div>
                    </div>)}
                  </ReactSortable>
                </div>
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={async () => {
                adminStore.setFeaturesList([...adminStore.featuresList.map((v, ix) => {
                  return ix == featureSel ? {
                    ...v,
                    title: featureTitle,
                    text: featureText,
                    images: [...parse(adminStore.featureImages)]
                  } : {...v}
                })])
                await saveData()
                setIsEditFeatureVisible(false)
              }}>Сохранить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isAddCompVisible}
        onClose={() => setIsAddCompVisible(false)}
        title="Добавить вид работы"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="feature_ttl">Заголовок</label>
                <input id="feature_ttl" type="text" placeholder="Заголовок" value={compTitle} onChange={(event) => setCompTitle(event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="feature_text">Текст</label>
                <input id="feature_text" type="text" placeholder="Текст..." value={compText} onChange={(event) => setCompText(event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="feature_text">Изображения</label>

                <Upload {...props} onChange={(info) => {
                  onImageUploadChange(info, async (url) => {
                    return await new Promise((resolve) => {
                      setCompImage(url)
                      setTimeout(() => {
                        resolve(true)
                      }, 1500)
                    })
                  })
                }}><div className={styles.xform_input__action}><PlusOutlined rev={null} /></div></Upload>
                <div>
                  <div className={styles.images_list}>
                    <div className={styles.img_item}>
                      <div className={styles.img_item__img}>
                        <div className={styles.mdp} style={{backgroundImage: `url(${compImage})`}}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={async () => {
                setIsAddCompVisible(false)
                setIsDataLoading(true)
                adminStore.setCompletesList([...adminStore.completesList, {
                  id: adminStore.completesList.length + 1,
                  title: compTitle,
                  text: compText,
                  image: compImage
                }])
                await saveData()
                setIsDataLoading(false)
              }}>Добавить</button>
            </div>
          </div>
        </>} />

        <Modal
          visible={isEditCompVisible}
          onClose={() => setIsEditCompVisible(false)}
          title="Изменить вид работы"
          content={<>
            <div className="modal_text">
              <div className={styles.xform}>
                <div className={styles.xform_input}>
                  <label htmlFor="feature_ttl">Заголовок</label>
                  <input id="feature_ttl" type="text" placeholder="Заголовок" value={compTitle} onChange={(event) => setCompTitle(event.target.value)} />
                </div>
                <div className={styles.xform_input}>
                  <label htmlFor="feature_text">Текст</label>
                  <input id="feature_text" type="text" placeholder="Текст..." value={compText} onChange={(event) => setCompText(event.target.value)} />
                </div>
                <div className={styles.xform_input}>
                  <label htmlFor="feature_text">Изображения</label>

                  <Upload {...props} onChange={(info) => {
                    onImageUploadChange(info, async (url) => {
                      return await new Promise((resolve) => {
                        setCompImage(url)
                        setTimeout(() => {
                          resolve(true)
                        }, 1500)
                      })
                    })
                  }}><div className={styles.xform_input__action}><PlusOutlined rev={null} /></div></Upload>
                  <div>
                    <div className={styles.images_list}>
                      <div className={styles.img_item}>
                        <div className={styles.img_item__img}>
                          <div className={styles.mdp} style={{backgroundImage: `url(${compImage})`}}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal_action">
              <div>
                <button onClick={async () => {
                  setIsEditCompVisible(false)
                  setIsDataLoading(true)
                  adminStore.setCompletesList([...adminStore.completesList.map((v, ix) => {
                    return compSel == ix ? {
                      ...v,
                      title: compTitle,
                      text: compText,
                      image: compImage
                    } : {...v}
                  })])
                  await saveData()
                  setIsDataLoading(false)
                }}>Сохранить</button>
              </div>
            </div>
          </>} />

          <Modal
        visible={isAddPartnerVisible}
        onClose={() => setIsAddPartnerVisible(false)}
        title="Добавить партнера"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="feature_text">Ссылка</label>
                <input id="feature_text" type="text" placeholder="https://..." value={partnerLink} onChange={(event) => setPartnerLink(event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="feature_text">Изображения</label>

                <Upload {...props} onChange={(info) => {
                  onImageUploadChange(info, async (url) => {
                    return await new Promise((resolve) => {
                      setPartnerUrl(url)
                      setTimeout(() => {
                        resolve(true)
                      }, 1500)
                    })
                  })
                }}><div className={styles.xform_input__action}><PlusOutlined rev={null} /></div></Upload>
                <div>
                  <div className={styles.images_list}>
                    <div className={styles.img_item}>
                      <div className={styles.img_item__img}>
                        <div className={styles.mdp} style={{backgroundImage: `url(${partnerUrl})`}}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={async () => {
                setIsAddPartnerVisible(false)
                setIsDataLoading(true)
                adminStore.setPartnersImages([...adminStore.partnersImages, {
                  id: adminStore.partnersImages.length + 1,
                  url: partnerUrl,
                  link: partnerLink
                }])
                await saveData()
                setIsDataLoading(false)
              }}>Добавить</button>
            </div>
          </div>
        </>} />

        <Modal
          visible={isEditPartnerVisible}
          onClose={() => setIsEditPartnerVisible(false)}
          title="Изменить партнера"
          content={<>
            <div className="modal_text">
              <div className={styles.xform}>
                <div className={styles.xform_input}>
                  <label htmlFor="feature_text">Ссылка</label>
                  <input id="feature_text" type="text" placeholder="https://..." value={partnerLink} onChange={(event) => setPartnerLink(event.target.value)} />
                </div>
                <div className={styles.xform_input}>
                  <label htmlFor="feature_text">Изображения</label>

                  <Upload {...props} onChange={(info) => {
                    onImageUploadChange(info, async (url) => {
                      return await new Promise((resolve) => {
                        setPartnerUrl(url)
                        setTimeout(() => {
                          resolve(true)
                        }, 1500)
                      })
                    })
                  }}><div className={styles.xform_input__action}><UploadOutlined rev={null} /></div></Upload>
                  <div>
                    <div className={styles.images_list}>
                      <div className={styles.img_item}>
                        <div className={styles.img_item__img}>
                          <div className={styles.mdp} style={{width: "230px", backgroundImage: `url(${partnerUrl})`}}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal_action">
              <div>
                <button onClick={async () => {
                  setIsEditPartnerVisible(false)
                  setIsDataLoading(true)
                  adminStore.setPartnersImages([...adminStore.partnersImages.map((v, ix) => {
                    return partnerSel == ix ? {
                      ...v,
                      url: partnerUrl,
                      link: partnerLink
                    } : {...v}
                  })])
                  await saveData()
                  setIsDataLoading(false)
                }}>Сохранить</button>
              </div>
            </div>
          </>} />

        <Modal
          visible={isDeletePartnerVisible}
          onClose={() => setIsDeletePartnerVisible(false)}
          title="Удалить партнера"
          content={<>
            <div className="modal_text">
              <p>Вы действительно хотите удалить партнера?</p>
            </div>
            <div className="modal_action">
              <div>
                <button onClick={async () => {
                  setIsDeletePartnerVisible(false)
                  setIsDataLoading(true)
                  adminStore.setPartnersImages([...adminStore.partnersImages.filter((v, ix) => ix != partnerSel)])
                  await saveData()
                  setIsDataLoading(false)
                }}>Удалить</button>
              </div>
            </div>
          </>} />

      <Modal
        visible={isAddPartnerPostVisible}
        onClose={() => setIsAddPartnerPostVisible(false)}
        title="Добавить поставщика"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="feature_text">Ссылка</label>
                <input id="feature_text" type="text" placeholder="https://..." value={partnerPostLink} onChange={(event) => setPartnerPostLink(event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="feature_text">Изображения</label>

                <Upload {...props} onChange={(info) => {
                  onImageUploadChange(info, async (url) => {
                    return await new Promise((resolve) => {
                      setPartnerPostUrl(url)
                      setTimeout(() => {
                        resolve(true)
                      }, 1500)
                    })
                  })
                }}><div className={styles.xform_input__action}><PlusOutlined rev={null} /></div></Upload>
                <div>
                  <div className={styles.images_list}>
                    <div className={styles.img_item}>
                      <div className={styles.img_item__img}>
                        <div className={styles.mdp} style={{backgroundImage: `url(${partnerPostUrl})`}}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={async () => {
                setIsAddPartnerPostVisible(false)
                setIsDataLoading(true)
                adminStore.setPartnersPostImages([...adminStore.partnersPostImages, {
                  id: adminStore.partnersPostImages.length + 1,
                  url: partnerPostUrl,
                  link: partnerPostLink
                }])
                await saveData()
                setIsDataLoading(false)
              }}>Добавить</button>
            </div>
          </div>
        </>} />

        <Modal
          visible={isEditPartnerPostVisible}
          onClose={() => setIsEditPartnerPostVisible(false)}
          title="Изменить поставщика"
          content={<>
            <div className="modal_text">
              <div className={styles.xform}>
                <div className={styles.xform_input}>
                  <label htmlFor="feature_text">Ссылка</label>
                  <input id="feature_text" type="text" placeholder="https://..." value={partnerPostLink} onChange={(event) => setPartnerPostLink(event.target.value)} />
                </div>
                <div className={styles.xform_input}>
                  <label htmlFor="feature_text">Изображения</label>

                  <Upload {...props} onChange={(info) => {
                    onImageUploadChange(info, async (url) => {
                      return await new Promise((resolve) => {
                        setPartnerPostUrl(url)
                        setTimeout(() => {
                          resolve(true)
                        }, 1500)
                      })
                    })
                  }}><div className={styles.xform_input__action}><UploadOutlined rev={null} /></div></Upload>
                  <div>
                    <div className={styles.images_list}>
                      <div className={styles.img_item}>
                        <div className={styles.img_item__img}>
                          <div className={styles.mdp} style={{width: "230px", backgroundImage: `url(${partnerPostUrl})`}}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal_action">
              <div>
                <button onClick={async () => {
                  setIsEditPartnerPostVisible(false)
                  setIsDataLoading(true)
                  adminStore.setPartnersPostImages([...adminStore.partnersPostImages.map((v, ix) => {
                    return partnerPostSel == ix ? {
                      ...v,
                      url: partnerPostUrl,
                      link: partnerPostLink
                    } : {...v}
                  })])
                  await saveData()
                  setIsDataLoading(false)
                }}>Сохранить</button>
              </div>
            </div>
          </>} />

        <Modal
          visible={isDeletePartnerPostVisible}
          onClose={() => setIsDeletePartnerPostVisible(false)}
          title="Удалить поставщика"
          content={<>
            <div className="modal_text">
              <p>Вы действительно хотите удалить поставщика?</p>
            </div>
            <div className="modal_action">
              <div>
                <button onClick={async () => {
                  setIsDeletePartnerPostVisible(false)
                  setIsDataLoading(true)
                  adminStore.setPartnersPostImages([...adminStore.partnersPostImages.filter((v, ix) => ix != partnerPostSel)])
                  await saveData()
                  setIsDataLoading(false)
                }}>Удалить</button>
              </div>
            </div>
          </>} />

      <Modal
        visible={isDeleteFeatureVisible}
        onClose={() => setIsDeleteFeatureVisible(false)}
        title="Удалить преимущество"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить запись из списка преимуществ?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={async () => {
                setIsDeleteFeatureVisible(false)
                setIsDataLoading(true)
                adminStore.setFeaturesList([...adminStore.featuresList.filter((v, ix) => ix != featureSel)])
                await saveData()
                setIsDataLoading(false)
              }}>Удалить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isDeleteCompVisible}
        onClose={() => setIsDeleteCompVisible(false)}
        title="Удалить вид работ"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить вид работ?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={async () => {
                setIsDeleteCompVisible(false)
                setIsDataLoading(true)
                adminStore.setCompletesList([...adminStore.completesList.filter((v, ix) => ix != compSel)])
                await saveData()
                setIsDataLoading(false)
              }}>Удалить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isDeleteBlockVisible}
        onClose={() => setIsDeleteBlockVisible(false)}
        title="Удалить блок"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить блок?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={async () => {
                setIsDeleteBlockVisible(false)
                setIsDataLoading(true)
                adminStore.setHeadBlocks([...adminStore.headBlocks.filter((v, ix) => ix != blockSel)])
                await saveData()
                setIsDataLoading(false)
              }}>Удалить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isDeleteBlagVisible}
        onClose={() => setIsDeleteBlagVisible(false)}
        title="Удалить письмо"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить благодарственное письмо?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={async () => {
                setIsDeleteBlagVisible(false)
                setIsDataLoading(true)
                adminStore.setBlagImages([...adminStore.blagImages.filter((v, ix) => ix != selectedImg)])
                setBlagImages(prev => prev = [...blagImages.filter((v, ix) => ix != selectedImg)])
                setTimeout(async () => {
                  await saveData()
                  setIsDataLoading(false)
                }, 1000)
              }}>Удалить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isDeleteCertVisible}
        onClose={() => setIsDeleteCertVisible(false)}
        title="Удалить сертификат"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить сертификат?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={async () => {
                setIsDeleteCertVisible(false)
                setIsDataLoading(true)
                adminStore.setCertsImages([...adminStore.certsImages.filter((v, ix) => ix != selectedImg)])
                setCertsImages(prev => prev = [...certsImages.filter((v, ix) => ix != selectedImg)])
                setTimeout(async () => {
                  await saveData()
                  setIsDataLoading(false)
                }, 1000)
              }}>Удалить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isDeleteAboutVisible}
        onClose={() => setIsDeleteAboutVisible(false)}
        title="Удалить изображение"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить изображение?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={async () => {
                setIsDeleteAboutVisible(false)
                setIsDataLoading(true)
                adminStore.setAboutImages([...adminStore.aboutImages.filter((v, ix) => ix != selectedImg)])
                await saveData()
                setIsDataLoading(false)
              }}>Удалить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isDeleteHeadVisible}
        onClose={() => setIsDeleteHeadVisible(false)}
        title="Удалить изображение"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить изображение?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={async () => {
                setIsDeleteHeadVisible(false)
                setIsDataLoading(true)
                adminStore.setHeadImages([...adminStore.headImages.filter((v, ix) => ix != selectedImg)])
                await saveData()
                setIsDataLoading(false)
              }}>Удалить</button>
            </div>
          </div>
        </>} />
    </>
  )
}

export default observer(HomeManager)