import { message, Switch, Upload, UploadProps } from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."
import { ApiService } from "../../../core/api/api"
import { PromoSale } from "../../../core/models/PromoSale"
import { API_URL, IMG_PLACEHOLDER, StorageKeys } from "../../../core/utils/defaults"
import { asyncForEach, translit } from "../../../core/utils/utils"

import { DeleteOutlined, EditOutlined, LoadingOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons"

import { useFetching } from "../../../core/hooks/useFetching"
import { ProjectsAll } from "../../../core/utils/projects"
import { IXImg, IXProject } from "../../../core/models/XProject"

import { ReactSortable } from "react-sortablejs"
import { RcFile } from "antd/es/upload"

import { Editor } from "@tinymce/tinymce-react"

import imageCompression from "browser-image-compression"
import Modal from "../../../components/Modal"

import styles from "../../../styles/AdminManager.module.scss"
import ManagerModal from "../../../components/ManagerModal"
import XCard from "../components/XCard/XCard"
import { IImage } from "../../../core/models/XHome"

interface IXImage {
  id: number
  type: string
  images?: string[]
  text?: string
}

const ProjectsManager: FC = () => {
  
  const { store, adminStore } = useContext(Context)
  const location = useLocation()

  const editorRef = useRef(null as any)
  const editorRef2 = useRef(null as any)

  const [actualProjs, setActualProjs] = useState([] as any[])
  const [offProjs, setOffProjs] = useState([] as any[])

  const [activeTab, setActiveTab] = useState(1)
  const [activeTabEd, setActiveTabEd] = useState(1)

  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isDeleteVisible, setIsDeleteVisible] = useState(false)
  const [isDeleteImgVisible, setIsDeleteImgVisible] = useState(false)
  const [isDeleteTempVisible, setIsDeleteTempVisible] = useState(false)
  
  const [selectedImg, setSelectedImg] = useState(0)
  const [selectedTemp, setSelectedTemp] = useState(0)

  const [projImage, setProjImage] = useState("")
  const [projTemp, setProjTemp] = useState("")
  const [projImageList, setProjImageList] = useState([] as string[])
  const [projAImageList, setProjAImageList] = useState([] as any[])
  const [projTempList, setProjTempList] = useState([] as string[])
  const [projImageListMp, setProjImageListMp] = useState([] as any[])
  const [projTempListMp, setProjTempListMp] = useState([] as any[])
  const [projImages, setProjImages] = useState([] as IXImage[])

  const [projSpeed, setProjSpeed] = useState("")
  const [projSpeedTemp, setProjSpeedTemp] = useState("")
  const [projTitle, setProjTitle] = useState("")
  const [projTitleEn, setProjTitleEn] = useState("")
  const [projFullname, setProjFullname] = useState("")
  const [projAddress, setProjAddress] = useState("")
  const [projAddressFull, setProjAddressFull] = useState("")
  const [projAddressFullEn, setProjAddressFullEn] = useState("")
  const [projAddressFull2, setProjAddressFull2] = useState("")
  const [projCategory, setProjCategory] = useState("")
  const [projCategoryEn, setProjCategoryEn] = useState("")
  const [projYear, setProjYear] = useState("")
  const [projArea, setProjArea] = useState("")
  const [projType, setProjType] = useState("")
  const [projAbout, setProjAbout] = useState("")
  const [projAboutEn, setProjAboutEn] = useState("")
  const [projAboutT, setProjAboutT] = useState("")
  const [projAboutEnT, setProjAboutEnT] = useState("")
  const [projIndex, setProjIndex] = useState("")

  const [contentTitle, setContentTitle] = useState("")
  const [contentTitleEn, setContentTitleEn] = useState("")
  const [contentText, setContentText] = useState("")
  const [contentTextEn, setContentTextEn] = useState("")

  const [contentType, setContentType] = useState("text")
  const [contentBlocks, setContentBlocks] = useState([] as any[])
  const [contentBlocksDark, setContentBlocksDark] = useState([] as any[])
  const [contentAlign, setContentAlign] = useState("")
  const [contentIndex, setContentIndex] = useState(0)
  const [contentItem, setContentItem] = useState(null as any | null)

  const [isContentDelete, setIsContentDelete] = useState(false)
  const [isContentEdit, setIsContentEdit] = useState(false)
  const [isContentAdd, setIsContentAdd] = useState(false)
  
  const [isAddFloorVisible, setIsAddFloorVisible] = useState(false)
  const [isEdtFloorVisible, setIsEdtFloorVisible] = useState(false)
  const [isDelFloorVisible, setIsDelFloorVisible] = useState(false)
  
  const [selectedFloor, setSelectedFloor] = useState(null as any | null)
  
  const [floorname, setFloorname] = useState("")
  const [floornameEn, setFloornameEn] = useState("")
  const [floorPlan, setFloorPlan] = useState("")
  const [blockPlan, setBlockPlan] = useState("")
  
  const [projStatusStr, setProjStatusStr] = useState("")
  const [projStatusStrEn, setProjStatusStrEn] = useState("")
  const [projStatus, setProjStatus] = useState(0)

  const [selectedProj, setSelectedProj] = useState(null as null | IXProject)

  const props3: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    beforeUpload: async (file, FileList) => {
      setIsDataLoading(prev => prev = true)
      if (file.type.includes("gif")) {
        return file
      } else {
        const options = { maxSizeMB: 3.0, maxWidth: 3920, useWebWorker: true }
        try {
          const compressedFile = await imageCompression(file, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          return compressedFile;
        } catch (error) {
          console.log(error);
        }
      }
    },
    onChange: async (info) => {
      setIsDataLoading(prev => prev = false)
      const { status } = info.file;
      if (status === 'done') {
        message.success(`Файл успешно загружен!`);
        console.log(info.file.response)
        setProjImageList(prev => prev = [...prev, info.file.response.urls[0]])
        setProjAImageList(prev => prev = [...prev, {id: 0, image: info.file.response.urls[0]}])
        setProjImageListMp(prev => prev = [...prev, {id: 0, url: info.file.response.urls[0]}])
        setProjImage(prev => prev = `${prev};${info.file.response.urls[0]}`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  }

  const props35: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    beforeUpload: async (file, FileList) => {
      setIsDataLoading(prev => prev = true)
      if (file.type.includes("gif")) {
        return file
      } else {
        const options = { maxSizeMB: 3.0, maxWidth: 3920, useWebWorker: true }
        try {
          const compressedFile = await imageCompression(file, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          return compressedFile;
        } catch (error) {
          console.log(error);
        }
      }
    },
    onChange: async (info) => {
      setIsDataLoading(prev => prev = false)
      const { status } = info.file;
      if (status === 'done') {
        message.success(`Файл успешно загружен!`);
        console.log(info.file.response)
        setProjTempList(prev => prev = [...prev, info.file.response.urls[0]])
        setProjTempListMp(prev => prev = [...prev, {id: prev.length + 2, image: info.file.response.urls[0]}])
        setProjTemp(prev => prev = `${prev};${info.file.response.urls[0]}`)
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  }

  const props34: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    beforeUpload: async (file: RcFile, FileList) => {
      setIsDataLoading(prev => prev = true)
      if (file.type.includes("gif")) {
        return file
      } else {
        const options = { maxSizeMB: 3.0, maxWidth: 3920, useWebWorker: true }
        try {
          const compressedFile = await imageCompression(file, options);
          console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
          return compressedFile;
        } catch (error) {
          console.log(error);
        }
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  }

  const props36: UploadProps = {
    name: 'images',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    headers: {
      "Authorization": "Bearer " + store.token
    },
    action: `${API_URL}/core/uploader/images`,
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    }
  }

  const [fetchProjects] = useFetching(async () => {
    const res = await ApiService.projects.getList()

    console.log(res)

    let projs: IXProject[] = res.data.projects

    projs = projs.sort((a, b) => {
      if (a.index! > b.index!) {
        return 1
      } else if (a.index! < b.index!) {
        return -1
      } else {
        return 0
      }
    })

    adminStore.setProjects(projs)
    setActualProjs(prev => prev = projs.filter((v: any) => v.status == 1))
    setOffProjs(prev => prev = projs.filter((v: any) => v.status == 0))

    store.setProjectTilts(2)
  })

  const selectProject = (proj: IXProject) => {
    setProjImage(proj.image!)
    setProjImageList(proj.image != "" ? proj.image!.split(";") : [])
    setProjAImageList(proj.image != "" ? proj.image!.split(";").map((v, ix) => {
      return {id: ix + 1, image: v}
    }) : [])
    setProjTempList(proj.temp != "" ? proj.temp!.split(";") : [])
    setProjTempListMp(prev => prev = proj.temp != "" ? proj.temp!.split(";").map((img, ix) => {
      console.log(img)
      return {
        id: ix + 1,
        image: img
      }
    }) : [])
    setProjImages(proj.images!)

    setProjSpeed(proj.speed!)
    setProjSpeedTemp(proj.speed_temp!)
    setProjTitle(proj.title!)
    setProjTitleEn(proj.title_en!)
    setProjFullname(proj.fullname!)
    setProjAddress(proj.address!)
    setProjAddressFull(proj.address_full!)
    setProjAddressFullEn(proj.address_full_en!)
    setProjAddressFull2(proj.address_full2!)
    setProjCategory(proj.category!)
    setProjCategoryEn(proj.category_en!)
    setProjYear(`${proj.year}`)
    setProjArea(`${proj.area}`)
    setProjType(proj.type!)
    setProjAbout(proj.about!)
    setProjAboutEn(proj.about_en!)

    setProjIndex(`${proj.index}`)

    setProjStatus(proj.status!)
    setProjStatusStr(proj.status_str!)
    setProjStatusStrEn(proj.status_str_en!)

    setSelectedProj(prev => prev = proj)
  }

  const addNewSaleAction = async () => {
    setIsDataLoading(prev => prev = true)

    const res = await ApiService.projects.create({
      index: adminStore.projects.length + 1,
      title: "Не заполнено",
      fullname: "",
      address: "",
      address_full: "",
      address_full2: "Не заполнено",
      year: "2023",
      category: "",
      area: 0,
      temp: "",
      speed_temp: 3000,
      image: "",
      images: [],
      about: "",
      status: 0,
      status_str: "",
      type: ""
    })

    console.log(res)

    fetchProjects()

    setActiveTab(prev => prev = 2)

    message.success("Новый проект успешно добавлен!")
    setIsDataLoading(prev => prev = false)
  }

  const deleteImgAction = async () => {
    setIsDeleteImgVisible(false)
    setProjImageList(prev => prev = prev.filter((v, ix) => ix != selectedImg))
    setProjAImageList(prev => prev = prev.filter((v, ix) => ix != selectedImg))
    setSelectedImg(prev => prev = 0)
  }

  const deleteTempAction = async () => {
    setIsDeleteTempVisible(false)
    setProjTempList(prev => prev = prev.filter((v, ix) => ix != selectedTemp))
    setProjTempListMp(prev => prev = prev.filter((v, ix) => ix != selectedTemp))
    setSelectedTemp(prev => prev = 0)
  }

  const saveSaleAction = async () => {
    setIsDataLoading(prev => prev = true)

    console.log(projAImageList)

    let newItem: any = {
      linkname: `${translit(projTitle)}`,
      index: parseInt(projIndex),
      image: projAImageList.map(v => v.image).join(";"),
      temp: projTempListMp.map(v => v.image).join(";"),
      images: projImages,
      title: projTitle,
      title_en: projTitleEn,
      speed: projSpeed,
      speed_temp: projSpeedTemp,
      fullname: projFullname,
      address: projAddress,
      category: projCategory,
      category_en: projCategoryEn,
      year: projYear == "" ? "0" : projYear,
      area: 0,
      type: projType,
      about: editorRef.current.getContent(),
      about_en: adminStore.transActive ? editorRef2.current.getContent() : "",
      status: projStatus,
      status_str: projStatusStr,
      status_str_en: projStatusStrEn
    }

    console.log(newItem)

    const res = await ApiService.projects.update(selectedProj!.id!, newItem)

    fetchProjects()

    const ext = adminStore.projects.find(v => v.id == selectedProj!.id!)

    let newArr: any[] = [
      ...adminStore.projects.filter(v => v.id != selectedProj!.id!),
      {...ext, ...newItem}
    ]
    adminStore.setProjects(newArr)
    localStorage.setItem(StorageKeys.PROJECTS, JSON.stringify(newArr))

    message.success("Проект успешно обновлен!")
    setIsDataLoading(prev => prev = false)
  }

  const deleteSaleAction = async () => {
    setIsDeleteVisible(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    const res = await ApiService.projects.delete(selectedProj!.id!)

    setSelectedProj(prev => prev = null)

    fetchProjects()

    let newArr: any[] = [
      ...adminStore.projects.filter(v => v.id != selectedProj!.id!)
    ]
    adminStore.setProjects(newArr)
    localStorage.setItem(StorageKeys.PROJECTS, JSON.stringify(newArr))

    message.success("Проект успешно удален!")
    setIsDataLoading(prev => prev = false)
  }

  const saveProjects = async () => {

    console.log(actualProjs, offProjs)

    let actPr = actualProjs.map((item, ix) => {
      return {...item, index: ix}
    })

    await asyncForEach(actPr, async (item: IXProject) => {
      const res = await ApiService.projects.update(item.id!, {
        index: item.index
      })

      console.log(res)
    })

    fetchProjects()

    message.success("Расположение проектов успешно обновлено!");
  }

  const addContentBlock = async () => {
    setIsContentAdd(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let newBlocks: any[] = [...selectedProj!.images!]

    newBlocks.push({
      id: newBlocks.length + 1,
      type: contentType,
      images: contentBlocks,
      text: contentText
    })

    const res = await ApiService.projects.update(selectedProj!.id!, {
      images: newBlocks
    })

    setSelectedProj(prev => {
      let newItem: any = {...prev}
      newItem.images = newBlocks
      return prev = newItem
    })
    setProjImages(prev => prev = newBlocks)

    message.success("Блок успешно добавлен!")

    setIsDataLoading(prev => prev = false)
  }

  const saveContentBlock = async () => {
    setIsContentEdit(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let newBlocks: any[] = []

    selectedProj!.images!.forEach((block: any, ix) => {
      if (block.id == contentItem.id) {
        newBlocks.push({
          ...block,
          text: contentText,
          images: contentBlocks
        })
      } else {
        newBlocks.push(block)
      }
    })

    const res = await ApiService.projects.update(selectedProj!.id!, {
      images: newBlocks
    })

    setSelectedProj(prev => {
      let newItem: any = {...prev}
      newItem.images = newBlocks
      return prev = newItem
    })
    setProjImages(prev => prev = newBlocks)

    message.success("Блок успешно обновлен!")

    setIsDataLoading(prev => prev = false)
  }

  const deleteContentBlock = async () => {
    setIsContentDelete(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let newBlocks: any[] = selectedProj!.images!.filter(v => v.id != contentItem.id)

    const res = await ApiService.projects.update(selectedProj!.id!, {
      images: newBlocks
    })

    setSelectedProj(prev => {
      let newItem: any = {...prev}
      newItem.images = newBlocks
      return prev = newItem
    })
    setProjImages(prev => prev = newBlocks)

    message.success("Блок успешно удален!")

    setIsDataLoading(prev => prev = false)
  }

  const getName = (name: string) => {
    if (name == "three") {
      return "Три изображения"
    } else if (name == "two") {
      return "Два изображения"
    } else if (name == "one") {
      return "Одно изображение"
    } else if (name == "threemulti") {
      return "Три изображения, разная высота"
    } else if (name == "text") {
      return "Текстовый блок"
    }
  }

  const closeManager = () => {
    adminStore.setIsManagerVisibleOp(false)
    
    document.body.style.overflowY = 'unset'
    document.getElementsByTagName("html")[0].style.overflowY = 'unset'
    
    setTimeout(() => {
      adminStore.setIsManagerVisible(false)
    }, 300)
  }

  useEffect(() => {
    store.setProjectTilts(store.projectTilts + 1)
  }, [actualProjs])

  useEffect(() => {
    store.setProjectTilts(0)
    setTimeout(() => {
      fetchProjects()
    }, 200);
  }, [])

  return (
    <>
      <div className={styles.manager_body__header}>
        <div className={styles.mbh_title}>
          <div>Панель Управления</div>
          <div>Проекты</div>
        </div>
        <div className={styles.mbh_close} onClick={() => closeManager()}><i className="fas fa-xmark"></i></div>
      </div>
      <div className={styles.manager_body__content}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={styles.grid_block}>
                <h3><span className="nomore" style={{
                  display: selectedProj != null ? "inline-block" : "none"
                }} onClick={() => setSelectedProj(prev => prev = null)}><i className="far fa-arrow-left-long"></i> </span>{selectedProj == null ? "Список проектов" : "Редактирование проекта"}</h3>
                
                {selectedProj == null ? <>
                  <div className={styles.xtabs + " " + styles.minimized + " " + styles[`active${activeTab}`]}>
                    <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 1)}>Активные</div>
                    <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 2)}>Отключенные</div>
                  </div>

                  <div className={styles.xactions}>
                    <div className={styles.xactions_item + " " + styles.success + (store.projectTilts < 4 ? " " + styles.disabled : "")} onClick={() => saveProjects()}>Сохранить</div>
                    <div className={styles.xactions_item} onClick={() => addNewSaleAction()}>Добавить проект</div>
                  </div>
                  
                  {activeTab == 1 ? <p className={styles.xdesc}>Вы можете менять расположение проектов.<br/>Для этого зажмите проект и перенесите на нужное место, взамен другого.</p> : <></>}
                    
                  {activeTab == 1 ? <div>
                    <ReactSortable className={styles.xgrid} list={activeTab == 1 ? actualProjs : offProjs} setList={activeTab == 1 ? setActualProjs : setOffProjs}>
                      {actualProjs.map((item, ix) => <div className={styles.xgrid_item}>
                        <XCard id={`${item.id}`} key={ix} draggable={true} onClick={() => selectProject(item)} item={item} />
                      </div>)}
                    </ReactSortable>
                  </div> : <div className={styles.xgrid}>
                    {offProjs.map((item, ix) => <div className={styles.xgrid_item}>
                      <XCard id={`${item.id}`} key={ix} draggable={false} onClick={() => selectProject(item)} item={item} />
                    </div>)}
                  </div>}
                </> : <>
                  <div className={styles.xactions}>
                    {activeTabEd == 2 ? <div className={styles.xactions_item + " " + styles.successs} onClick={(evt) => {
                      setContentType("text")
                      setContentTitle("")
                      setContentTitleEn("")
                      setContentText("")
                      setContentTextEn("")
                      setContentAlign("no")
                      setContentIndex(0)
                      setContentBlocks([IMG_PLACEHOLDER])
                      setContentItem(null)
                      setIsContentAdd(prev => prev = true)
                    }}>Добавить блок</div> : <></>}
                    {activeTabEd == 1 || activeTabEd == 3 ? <div className={styles.xactions_item + " " + styles.success} style={{marginLeft: "12px"}} onClick={() => saveSaleAction()}>Сохранить</div> : <></>}
                    {activeTabEd == 1 ? <div className={styles.xactions_item + " " + styles.danger} onClick={() => setIsDeleteVisible(true)}>Удалить</div> : <></>}
                  </div>

                  <div className={styles.xtabs + " " + styles.minimized + " " + styles[`active${activeTabEd}`]}>
                    <div className={styles.xtabs_item} onClick={() => setActiveTabEd(prev => prev = 1)}>Основное</div>
                    <div className={styles.xtabs_item} onClick={() => setActiveTabEd(prev => prev = 2)}>Контент</div>
                  </div>
                  
                  {activeTabEd == 1 ? <div className="row">
                    <div className="col-12 col-md-8">
                      <div className={styles.xform}>
                        
                        <div className={styles.xform_input}>
                          <label>Статус</label>
                          <Switch defaultChecked={projStatus == 1} checked={projStatus == 1} onChange={(event) => {
                            if (projImageList.length == 0) {
                              message.error("Для начала загрузите изображения!")
                              setProjStatus(prev => prev = 0)
                            } else {
                              setProjStatus(prev => prev = event ? 1 : 0)
                            }
                          }} /> {projStatus == 1 ? "Активен" : "Отключен"}
                        </div>

                        <div style={{position: "relative", paddingBottom: "16px", borderBottom: "1px solid #ececec", marginBottom: "16px"}}>
                          <h3>Изображения</h3>

                          <div className={styles.xactions} style={{top: "-10px"}}>
                            <Upload {...props3}><div className={styles.xactions_item + " " + styles.successs}>Добавить изображение</div></Upload>
                          </div>

                          <p className={styles.xdesc}>Вы можете менять расположение изображений.<br/>Для этого зажмите картинку и перенесите на нужное место, взамен другой.<br/><b>Первое изображение всегда будет главным, остальные в слайдер.</b><br/>{projImageList.length == 0 ? <b><small style={{color: "red"}}>Без изображений, проект не будет отображаться на странице проектов.</small></b> : <></>}</p>
                              
                          {/*<ReactSortable className={styles.flexed_imgs} list={projImageList} setList={(ns: any[], sortable, store) => {
                            console.log(store.dragging)
                            if (store.dragging != null) {
                              updateBlocks(ns)
                            }
                          }}>
                            
                        </ReactSortable>*/}

                          <div>
                            <ReactSortable className={styles.flexed_imgs} list={projAImageList} setList={(newLst) => {
                              setProjAImageList(prev => prev = newLst)
                            }}>
                              {projAImageList.map((image, ix, imgs) => <div 
                                draggable={true}
                                key={ix}
                                id={`${ix}`}
                                className={ix == 0 && imgs.length > 1 ? styles.fximgx : ""}
                                style={{backgroundImage: `url(${image.image})`}}>
                                <DeleteOutlined rev={null} onClick={(event) => {
                                  event.preventDefault()
                                  event.stopPropagation()

                                  setSelectedImg(ix)
                                  setIsDeleteImgVisible(true)
                                }} />
                              </div>)}
                            </ReactSortable>
                          </div>

                          <div className={styles.xform_input} style={{
                            marginBottom: "4px",
                            marginTop: "8px"
                          }}>
                            <label htmlFor="proj_spd">Скорость смены слайдов</label>
                            <input type="number" id="proj_spd" placeholder="Скорость смены слайдов" value={projSpeed} onChange={(event) => {
                              if (event.target.value.match(/^\d+$/)) {
                                setProjSpeed(prev => prev = event.target.value)
                              }
                            }} />
                          </div>
                        </div>
                          
                        <div className={styles.flexed}>
                          <div className={styles.xform_input}>
                            <label htmlFor="proj_ttl">Заголовок <small style={{color: "red"}}>(обязательно)</small></label>
                            <input type="text" id="proj_ttl" placeholder="Заголовок" value={projTitle} onChange={(event) => setProjTitle(prev => prev = event.target.value)} />
                          </div>
                        </div>

                        {adminStore.transActive ? <div className={styles.flexed}>
                          <div className={styles.xform_input}>
                            <label htmlFor="proj_ttl_en">Заголовок (англ.)</label>
                            <input type="text" id="proj_ttl_en" placeholder="Заголовок (англ.)" value={projTitleEn} onChange={(event) => setProjTitleEn(prev => prev = event.target.value)} />
                          </div>
                        </div> : <></>}


                        <div className={styles.flexed}>
                          <div className={styles.xform_input}>
                            <label htmlFor="proj_year">Год</label>
                            <input type="text" min={0} id="proj_year" placeholder="Год" value={projYear} onChange={(event) => {
                              // if (event.target.value.match(/^\d+$/) || event.target.value == "") {
                                setProjYear(prev => prev = event.target.value)
                              // }
                            }} />
                          </div>
                          <div className={styles.xform_input} style={{minWidth: "30%"}}>
                            <label htmlFor="proj_st_p">Стадия проекта</label>
                            <input type="text" id="proj_st_p" placeholder="Стадия проекта" value={projStatusStr} onChange={(event) => setProjStatusStr(prev => prev = event.target.value)} />
                          </div>
                          {adminStore.transActive ? <div className={styles.xform_input} style={{minWidth: "30%"}}>
                            <label htmlFor="proj_st_p_en">Стадия проекта (англ.)</label>
                            <input type="text" id="proj_st_p_en" placeholder="Стадия проекта (англ.)" value={projStatusStrEn} onChange={(event) => setProjStatusStrEn(prev => prev = event.target.value)} />
                          </div> : <></>}
                        </div>

                        {/* <div className={styles.flexed}>
                          <div className={styles.xform_input}>
                            <label htmlFor="proj_area">Площадь</label>
                            <input type="number" min={0} id="proj_area" placeholder="Площадь" value={projArea} onChange={(event) => {
                              if (event.target.value.match(/^\d+$/) || event.target.value == "") {
                                setProjArea(prev => prev = event.target.value)
                              }
                            }} />
                          </div>
                          <div className={styles.xform_input}>
                            <label htmlFor="proj_cat">Категория</label>
                            <input type="text" id="proj_cat" placeholder="Категория" value={projCategory} onChange={(event) => setProjCategory(prev => prev = event.target.value)} />
                          </div>
                          {adminStore.transActive ? <div className={styles.xform_input} style={{minWidth: "30%"}}>
                            <label htmlFor="proj_cat_en">Категория (англ.)</label>
                            <input type="text" id="proj_cat_en" placeholder="Категория (англ.)" value={projCategoryEn} onChange={(event) => setProjCategoryEn(prev => prev = event.target.value)} />
                          </div> : <></>}
                        </div> */}

                        <div className={styles.xform_input} style={{maxWidth: "unset"}}>
                          <label htmlFor="proj_about">Описание</label>
                          <Editor
                            onInit={(evt, editor) => editorRef.current = editor}
                            initialValue={projAbout}
                            onEditorChange={(content: string) => {
                              setProjAboutT(prev => prev = content)
                            }}
                            apiKey="ml9lkgxbsm7v5p1rdynza2e1p25k0p8v22ebhmm4w54h3spb"
                            init={{
                              height: 300,
                              menubar: true,
                              language: "ru",
                              plugins: [
                                'autolink', 'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                                'fullscreen','insertdatetime','media','table','help','wordcount', 'code'
                              ],
                              toolbar: 'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | help | code',
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #000;}'
                            }}
                          />
                        </div>

                        <div className={styles.xform_input} style={{
                          display: adminStore.transActive ? "block" : "none",
                          maxWidth: "unset"
                        }}>
                          <label htmlFor="proj_about">Описание (англ.)</label>
                          <Editor
                            onInit={(evt, editor) => editorRef2.current = editor}
                            initialValue={projAboutEn}
                            onEditorChange={(content: string) => {
                              setProjAboutEnT(prev => prev = content)
                            }}
                            apiKey="ml9lkgxbsm7v5p1rdynza2e1p25k0p8v22ebhmm4w54h3spb"
                            init={{
                              height: 300,
                              menubar: true,
                              language: "ru",
                              plugins: [
                                'autolink', 'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                                'fullscreen','insertdatetime','media','table','help','wordcount', 'code'
                              ],
                              toolbar: 'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | help | code',
                              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; color: #000;}'
                            }}
                          />
                        </div>
                      
                      </div>
                    </div>
                  </div> : <></>}
                  
                  {activeTabEd == 2 ? <div className="row">
                    <div className="col-12 col-md-12">
                      <div className={styles.xform} style={{position: "relative"}}>

                        {selectedProj.images!.length == 0 ? <p className={styles.descript}>Блоки отсутствуют... <a href="#" onClick={(evt) => {
                          evt.preventDefault()
                          evt.stopPropagation()

                          setContentType("text")
                          setContentText("")
                          setContentBlocks([IMG_PLACEHOLDER, IMG_PLACEHOLDER])
                          setContentItem(null)
                          setIsContentAdd(prev => prev = true)
                        }}>Добавьте</a> первый блок в проекте!</p> : <></>}

                      <ReactSortable className={styles.xgrid} list={projImages} setList={(ns: any[], sortable, store) => {
                        setProjImages(ns)
                      }}>
                        {selectedProj != null ? projImages.map((item, ix: number) => <div 
                          draggable={true}
                          key={ix}
                          id={`${ix}`}
                          className={styles.images_bl}>
                          <div className={styles.images_bl__edit} onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()

                            setContentType(item.type)
                            setContentBlocks(item.images!)
                            setContentText(item.text!)
                            setContentIndex(ix)
                            setContentItem(item)
                            setIsContentEdit(prev => prev = true)
                          }}><EditOutlined rev={null} /></div>
                          <div className={styles.images_bl__del} onClick={(event) => {
                            event.preventDefault()
                            event.stopPropagation()

                            setContentIndex(ix)
                            setContentItem(item)
                            setIsContentDelete(prev => prev = true)
                          }}><DeleteOutlined rev={null} /></div>
                          <div className={styles.images_bl__type}>{getName(item.type)}</div>
                          {item.type != "text" ? <div className={styles.images_bl__images}>
                            {item.images!.map((im: string) => <div style={{backgroundImage: `url(${im})`}}></div>)}
                          </div> : <div className={styles.txt}>
                            <div></div>
                            <div>{item.text}</div>
                          </div>}
                        </div>) : <></>}
                      </ReactSortable>

                      </div>
                    </div>
                  </div> : <></>}
                </>}

              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        visible={isContentAdd}
        title="Добавить блок"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              
              <div className={styles.xform_input}>
                <label htmlFor="bl_type">Тип блока</label>
                <span className={styles.selc}>
                  <select name="bl_type" id="bl_type" value={contentType} onChange={(event) => {
                    setContentType(prev => prev = event.target.value)
                    
                    if (event.target.value == "three") {
                      setContentBlocks([IMG_PLACEHOLDER, IMG_PLACEHOLDER, IMG_PLACEHOLDER])
                    } else if (event.target.value == "two") {
                      setContentBlocks([IMG_PLACEHOLDER, IMG_PLACEHOLDER])
                    } else if (event.target.value == "one") {
                      setContentBlocks([IMG_PLACEHOLDER])
                    }
                  }}>
                    <option value="text">Текстовый блок</option>
                    <option value="one">Одно изображение</option>
                    <option value="two">Два изображения</option>
                    <option value="three">Три изображения</option>
                  </select>
                </span>
              </div>

              {contentType == "text" ? <div className={styles.flexed}>
                <div className={styles.xform_input}>
                  <label htmlFor="">Текст</label>
                  <textarea rows={5} placeholder="Текст" value={contentText} onChange={(event) => setContentText(event.target.value)}></textarea>
                </div>
                {adminStore.transActive ? <div className={styles.xform_input}>
                  <label htmlFor="">Текст (англ.)</label>
                  <textarea rows={5} placeholder="Текст (англ.)" value={contentTextEn} onChange={(event) => setContentTextEn(event.target.value)}></textarea>
                </div> : <></>}
              </div> : <></>}

              {contentType != "text" ? <div className={styles.xform_input + " " + styles.bigly}>
                <label htmlFor="bl_align">Изображения</label>
                <div className={styles.images_list}>
                  {contentBlocks.map((item, ixx) => <div className={styles.img_item}>
                    <div className={styles.img_item__img}>
                      <Upload {...props34} onChange={(info) => {
                        setIsDataLoading(prev => prev = false)
                        const { status } = info.file;
                        if (status === 'done') {
                          message.success(`Файл успешно загружен!`);
                          console.log(info.file.response)
                          
                          setContentBlocks(prev => {
                            let newLst: any[] = []
                            
                            prev.forEach((element, ixxx) => {
                              if (ixxx == ixx) {
                                newLst.push(info.file.response.urls[0])
                              } else {
                                newLst.push(element)
                              }
                            })

                            return prev = newLst
                          })
                        }
                      }}><div className={styles.mdp} style={{backgroundImage: `url(${item})`}}><span className={styles.mddp}><UploadOutlined rev={null} /></span></div></Upload>
                    </div>
                  </div>)}
                </div>
              </div> : <></>}

            </div>
          </div>
          <div className="modal_action modal_actionx">
            <div>
              <button onClick={() => addContentBlock()} style={{marginBottom: "0px"}}>Добавить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsContentAdd(false)} />

      <Modal
        visible={isContentEdit}
        title="Изменить блок"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="bl_type">Тип блока</label>
                <span className={styles.selc}>
                  <select disabled={true} name="bl_type" id="bl_type" value={contentType} onChange={(event) => {
                    // setContentType(prev => prev = event.target.value)
                  }}>
                    <option value="text">Текстовый блок</option>
                    <option value="one">Одно изображение</option>
                    <option value="two">Два изображения</option>
                    <option value="three">Три изображения</option>
                  </select>
                </span>
              </div>
              
              {contentType == "text" ? <>
                <div className={styles.xform_input}>
                  <label htmlFor="">Текст</label>
                  <textarea rows={5} placeholder="Текст" value={contentText} onChange={(event) => setContentText(event.target.value)}></textarea>
                </div>
                {adminStore.transActive ? <div className={styles.xform_input}>
                  <label htmlFor="">Текст (англ.)</label>
                  <textarea rows={5} placeholder="Текст (англ.)" value={contentTextEn} onChange={(event) => setContentTextEn(event.target.value)}></textarea>
                </div> : <></>}
              </> : <></>}

              {contentType != "text" ? <div className={styles.xform_input} style={{display: contentType == "text" ? "none" : "block"}}>
                <label htmlFor="bl_align">Изображения</label>
                <div className={styles.images_list}>
                  {contentBlocks.map((item, ixx) => <div className={styles.img_item}>
                    <div className={styles.img_item__img}>
                      <Upload {...props34} onChange={(info) => {
                        setIsDataLoading(prev => prev = false)
                        const { status } = info.file;
                        if (status === 'done') {
                          message.success(`Файл успешно загружен!`);
                          console.log(info.file.response)
                          
                          setContentBlocks(prev => {
                            let newLst: any[] = []
                            
                            prev.forEach((element, ixxx) => {
                              if (ixxx == ixx) {
                                newLst.push(info.file.response.urls[0])
                              } else {
                                newLst.push(element)
                              }
                            })

                            return prev = newLst
                          })
                        }
                      }}><div className={styles.mdp} style={{backgroundImage: `url(${item})`}}><span className={styles.mddp}><UploadOutlined rev={null} /></span></div></Upload>
                    </div>
                  </div>)}
                </div>
              </div> : <></>}
            </div>
          </div>
          <div className="modal_action modal_actionx">
            <div>
              <button onClick={() => saveContentBlock()} style={{marginBottom: "0px"}}>Сохранить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsContentEdit(false)} />

      <Modal
        visible={isContentDelete}
        title="Удалить блок"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить этот блок?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deleteContentBlock()} style={{marginBottom: "0px"}}>Удалить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsContentDelete(false)} />

      <Modal
        visible={isDeleteVisible}
        title="Удалить проект"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить проект?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deleteSaleAction()} style={{marginBottom: "0px"}}>Удалить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsDeleteVisible(false)} />

      <Modal
        visible={isDeleteImgVisible}
        onClose={() => setIsDeleteImgVisible(false)}
        title="Удалить изображение"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить изображение?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deleteImgAction()}>Удалить</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isDataLoading}
        title="Загрузка..."
        content={<>
          <div className="modal_text">
            <p style={{textAlign: "center", padding: "32px 0"}}><LoadingOutlined rev={null} style={{color: "#000", fontSize: "42px"}} /></p>
          </div>
        </>}
        onClose={() => setIsDataLoading(false)} />
    </>
  )
}

export default observer(ProjectsManager)