import { Menu, MenuProps } from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import {FormattedMessage} from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { Context } from ".."
import useWindowDimensions from "../core/hooks/useWindowDimensions"
import {LOCALES} from "../core/i18n/locales"
import { isNullOrUndefined, translit } from "../core/utils/utils"

import styles from "../styles/Header.module.scss"

interface HeaderProps {
  isMobile?: boolean
  onOpenMenu?: any
  goToSection?: any
  onBackCall?: any
}

const Header: FC<HeaderProps> = ({
  onOpenMenu = null, goToSection = null, onBackCall = null, isMobile = false
}) => {

  const { store, adminStore } = useContext(Context)
  const { width, height } = useWindowDimensions()
  const navigate = useNavigate()
  const location = useLocation()

  const [headerToggled, setHeaderToggled] = useState(true)
  const [headerTgl, setHeaderTgl] = useState(false)

  const [isMenuShown, setIsMenuShown] = useState(false)
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  
  const [items, setItems] = useState([] as MenuProps['items'])

  const onMouseUp = (e: any) => {
    var container = document.getElementById("ddp_menu")

    console.log("PRE-FIRREEEE", e)
    if (container != null && !container.contains(e.target)) {
      //container.style.display = 'none'
      console.log("FIRREEEE!!!", e)
      closeMenu()
    }
  }

  const openMenu = () => {
    setIsMenuShown(prev => prev = true)
    // if (width < 1280) {
      document.getElementById("root")!.style.overflowY = "hidden"
      document.getElementsByTagName("html")[0].style.overflowY = "hidden"
      document.body.style.overflowY = "hidden"

      document.addEventListener('mouseup', onMouseUp)
    // }
    setTimeout(() => {
      setIsMenuVisible(prev => prev = true)
      setTimeout(() => {
        setIsMenuOpen(prev => prev = true)
      }, 300)
    }, 100)
  }

  const closeMenu = () => {
    setIsMenuVisible(prev => prev = false)
    setIsMenuOpen(prev => prev = false)
    // if (width < 1280) {
      document.getElementById("root")!.style.overflowY = "scroll"
      document.getElementsByTagName("html")[0].style.overflowY = "hidden"
      document.body.style.overflowY = "hidden"

      document.removeEventListener('mouseup', onMouseUp)
    // }
    setTimeout(() => {
      setIsMenuShown(prev => prev = false);
    }, 300);
  }

  const goTop = () => {
    if (store.menuSelected != 0) {
      store.setIsHomeBlockVisible(false);
      store.setIsHeaderLocked(false);
      store.setSelectedSlide(0);
      setTimeout(() => {
        store.setMenuSelected(0);
        setTimeout(() => {
          store.setIsHomeBlockVisible(true);
        }, 100);
      }, 200);
    }

    const section = document.querySelector(`#top`);
    if (!isNullOrUndefined(section)) {
      section.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

  const navTo = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    
    let path = "";

    if (event.target.nodeName.toLowerCase() == "img") {
      path = event.target.parentElement.pathname;
    } else {
      path = event.target.pathname;
    }

    console.log(path, location.pathname, event);

    if (path == location.pathname) {
      return
    }

    store.setIsFaderVisible(true)
    setTimeout(() => {
      store.setIsFaderShown(true)

      window.scroll(0, 0)

      setTimeout(() => {
        navigate(path)
      }, 300)
    }, 100)
  }

  const trimPhone = (phone: string) => {
    let phn = phone
    phn = phn.replaceAll(" ", "")
    phn = phn.replaceAll("-", "")
    phn = phn.replaceAll(")", "")
    phn = phn.replaceAll("(", "")
    return phn
  }

  const isHeaderBg = () => {
    return (
      (location.pathname == "/" && store.isHeaderLocked) || 
      (location.pathname.includes("/projects") && store.isHeaderLocked) || 
      location.pathname == "/projects" ||
      location.pathname == "/features" ||
      location.pathname == "/about"
    )
  }

  const runAnimations = () => {
    const logo = document.getElementById("logo")
    const menuItems = [
      document.getElementById("menu1"),
      document.getElementById("menu2"),
      document.getElementById("menu3"),
      document.getElementById("menu4"),
      document.getElementById("menu5")
    ]

    if (logo != null) {
      logo.style.opacity = "1"
    }

    menuItems.forEach((item, ix) => {
      if (item != null) {
        item.style.opacity = "1"
        item.style.transform = "scale(1)"
      }
    })
  }

  useEffect(() => {
    let scrFix = 0;

    const onScroll = (event: any) => {
      const scrollTop = event.target.scrollTop;
      
      // if (width <= 768 && location.pathname == "/") {
      //   return
      // }

      // if (scrollTop >= 60) {
      //   store.setIsHeaderLocked(true)
      // } else {
      //   store.setIsHeaderLocked(false)
      // }

      // console.log(store.xScr)

      if (scrollTop < scrFix && scrollTop > store.xScr) {
        setHeaderToggled(prev => prev = true)
      } else if (scrollTop > store.xScr) {
        setHeaderToggled(prev => prev = false)
      }

      scrFix = parseInt(`${scrollTop}`);
      
      // if (location.pathname.includes("/projects") && store.selectedProject != null) {
      //   if (scrollTop > window.innerHeight - 50) {
      //     setHeaderTgl(prev => prev = true)
      //   } else {
      //     setHeaderTgl(prev => prev = false)
      //   }
      // } else {
      //   setHeaderTgl(prev => prev = false)
      // }
    }

    setTimeout(() => {
      runAnimations()
    }, 300)
    
    document.getElementById("root")!.addEventListener('scroll', onScroll)

    return () => {
      document.getElementById("root")!.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <>
      <header className={styles.header + (!headerToggled && !isMenuShown ? " " + styles.fixed : "") + (store.isHeaderLocked ? " " + styles.white : "") + (location.pathname.includes("/projects") && !headerTgl && store.selectedProject != null ? " " : "")} style={{
        backgroundColor: isHeaderBg() ? "#255CD8" : "transparent"
      }}>
        <div className="container">
          <div className="row" style={width > 960 ? {} : {alignItems: "center"}}>
            <div className={(width > 960 ? "col-6 col-md-3 " : "col-6 ")} style={{
              display: "flex", alignItems: "center", justifyContent: "center"
            }}>
              <div id="logo" className={styles.logo} style={{opacity: 0}}><a href="/"><img src={!headerTgl && location.pathname.includes("/projects") && store.selectedProject != null ? `/assets/images/logo.svg` : `/assets/images/logo.svg`} /></a></div>
            </div>
            <div style={width > 960 ? {display: "none"} : {}} className={(width > 960 ? "col-6 col-md-6 " : "col-6 ") + styles.ending}>
              <div id="mob_toggler" className={styles.mn_dp_open + (isMenuVisible ? " " + styles.openned : "")} onClick={() => isMenuShown ? closeMenu() : openMenu()}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 1.3501H0" stroke={isHeaderBg() ? "white" : "white"} stroke-width="1.8"/>
                  <path d="M20 9.9001H0" stroke={isHeaderBg() ? "white" : "white"} stroke-width="1.8"/>
                  <path d="M20 18.8001H0" stroke={isHeaderBg() ? "white" : "white"} stroke-width="1.8"/>
                </svg>
              </div>
            </div>
            <div style={width > 960 ? {} : {display: "none"}} className={(width > 960 ? "col-6 col-md-9 " : "col-6 ") + styles.ending}>
              <div className={styles.menu}>
                <a id="menu1" style={{opacity: 0, transform: "scale(1)"}} href="/projects" className={styles.menu_item}><FormattedMessage id="menu_projects" /></a>
                {adminStore.featSettings.status == true ? <a id="menu2" style={{opacity: 0, transform: "scale(1)"}} href="/features" className={styles.menu_item}><FormattedMessage id="menu_contacts" /></a> : <></>}
                <a id="menu3" style={{opacity: 0, transform: "scale(1)"}} href="/about" className={styles.menu_item}><FormattedMessage id="menu_about" /></a>
                <a id="menu4" style={{opacity: 0, transform: "scale(1)"}} href={adminStore.footerData.hdr_vacs} className={styles.menu_item}><FormattedMessage id="menu_vacs" /></a>
                <a id="menu5" style={{opacity: 0, transform: "scale(1)"}} href={`tel:${trimPhone(adminStore.footerData.phone)}`} className={styles.menu_item}>{adminStore.footerData.phone}</a>
              </div>
            </div>
            <div className="col-12">
              <div id="ddp_menu" className={styles.drop_menu + (isMenuOpen ? " " + styles.openned : "")} style={width > 960 ? {height: !isMenuVisible ? "0px" : "266px"} : {right: !isMenuVisible ? "-100%" : "0%"}}>
                <div className={styles.mask}></div>
                <div className={styles.dm_items + (!adminStore.transActive ? " " + styles.mp : "")}>
                  <a href="/projects"><FormattedMessage id="menu_projects" /></a>
                  {adminStore.featSettings.status == true ? <a href="/features"><FormattedMessage id="menu_contacts" /></a> : <></>}
                  <a href="/about"><FormattedMessage id="menu_about" /></a>
                  <a href={adminStore.footerData.hdr_vacs}><FormattedMessage id="menu_vacs" /></a>
                  
                  <div className={styles.xbtm}>
                    <a href={`tel:${trimPhone(adminStore.footerData.phone)}`}>{adminStore.footerData.phone}</a>
                    <a href={`mailto:${trimPhone(adminStore.footerData.links[1].url)}`}>{adminStore.footerData.links[1].name}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default observer(Header)