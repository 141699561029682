import { message, Switch } from "antd"
import { observer } from "mobx-react-lite"
import { FC, useContext, useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."
import { ApiService } from "../../../core/api/api"
import { StorageKeys } from "../../../core/utils/defaults"

import Modal from "../../../components/Modal"

import styles from "../../../styles/AdminManager.module.scss"
import {IXSocial} from "../../../core/models/XContacts"

const CoreManager: FC = () => {

  const { adminStore, store } = useContext(Context)
  const location = useLocation()

  const [activeTab, setActiveTab] = useState(1)

  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isEditVisible, setIsEditVisible] = useState(false)
 
  const [isAddSocialVisible, setIsAddSocialVisible] = useState(false)
  const [isEdtSocialVisible, setIsEdtSocialVisible] = useState(false)
  const [isDelSocialVisible, setIsDelSocialVisible] = useState(false)
  
  const [isAddLinkVisible, setIsAddLinkVisible] = useState(false)
  const [isEdtLinkVisible, setIsEdtLinkVisible] = useState(false)
  const [isDelLinkVisible, setIsDelLinkVisible] = useState(false)
  
  const [transName, setTransName] = useState("")
  const [transValue, setTransValue] = useState("")
  const [transValueEn, setTransValueEn] = useState("")
  const [selectedTrans, setSelectedTrans] = useState(null as any | null)

  const [ftPhone, setFtPhone] = useState("")
  const [ftAddress, setFtAddress] = useState("")
  const [ftAddressEn, setFtAddressEn] = useState("")
  const [ftAddressUrl, setFtAddressUrl] = useState("")
  const [ftCopys, setFtCopys] = useState("")
  const [ftCopysEn, setFtCopysEn] = useState("")
  const [hdrVacs, setHdrVacs] = useState("")
  
  const [ftSocials, setFtSocials] = useState([] as any[])
  const [ftLinks, setFtLinks] = useState([] as any[])

  const [socialSel, setSocialSel] = useState(0)
  const [socialName, setSocialName] = useState("")
  const [socialNameEn, setSocialNameEn] = useState("")
  const [socialUrl, setSocialUrl] = useState("")

  const [linkSel, setLinkSel] = useState(0)
  const [linkName, setLinkName] = useState("")
  const [linkNameEn, setLinkNameEn] = useState("")
  const [linkUrl, setLinkUrl] = useState("")

  const [oldPwd, setOldPwd] = useState("")
  const [newPwd, setNewPwd] = useState("")
  const [newPwdRpt, setNewPwdRpt] = useState("")

  const savePwd = async () => {
    const res = await ApiService.users.updateAccount({
      password: newPwd
    })

    setNewPwd(prev => prev = "")
    setNewPwdRpt(prev => prev = "")

    message.success("Пароль успешно изменен!")
  }

  const isPwdFilled = () => {
    if (
      newPwd.length < 3 || newPwdRpt.length < 3
    ) {
      return false
    } else {
      if (newPwd == newPwdRpt) {
        return true
      } else {
        return false
      }
    }
  }
  
  const closeManager = () => {
    adminStore.setIsManagerVisibleOp(false)
    
    document.body.style.overflowY = 'unset'
    document.getElementsByTagName("html")[0].style.overflowY = 'unset'
    
    setTimeout(() => {
      adminStore.setIsManagerVisible(false)
    }, 300)
  }

  const isFooterChanged = () => {
    if (
      ftPhone != adminStore.footerData.phone ||
      ftCopys != adminStore.footerData.inn ||
      ftCopysEn != adminStore.footerData.inn_en ||
      ftAddress != adminStore.footerData.address ||
      ftAddressEn != adminStore.footerData.address_en ||
      ftAddressUrl != adminStore.footerData.address_url
    ) {
      return true
    } else {
      return false
    }
  }

  const saveFooter = async (callback?: (props: any) => any) => {
    setIsDataLoading(true)

    let attrs: any = {
      phone: ftPhone,
      hdr_vacs: hdrVacs,
      inn: ftCopys,
      inn_en: ftCopysEn,
      address: ftAddress,
      address_en: ftAddressEn,
      address_url: ftAddressUrl,
      socials: ftSocials,
      links: ftLinks
    }

    if (callback != null) {
      attrs = callback(attrs)
    }

    const res = await ApiService.core.updateSetting(3, {
      data: attrs
    })

    adminStore.setFooterData(attrs)

    fetchData()

    setIsDataLoading(false)
    message.success("Данные успешно обновлены!")
  }

  const toggleTrans = async () => {
    const newSt = !adminStore.transActive
    const res = await ApiService.core.updateSetting(7, {
      data: {value: newSt}
    })

    localStorage.setItem(StorageKeys.SETTINGS_TRANS_ST, `${newSt}`)
    adminStore.setTransActive(!adminStore.transActive)

    message.success("Функция перевода обновлена!")
  }

  const selectTrans = (item: any) => {
    setTransName(item.name)
    setTransValue(item.value)
    setTransValueEn(item.value_en)
    setSelectedTrans(item)
    setIsEditVisible(true)
  }

  const saveTrans = async () => {
    setIsEditVisible(prev => prev = false)
    setIsDataLoading(prev => prev = true)

    let newData: any[] = []
    adminStore.transes.forEach((val: any) => {
      if (val.name == transName) {
        newData.push({
          name: val.name,
          value: transValue,
          value_en: transValueEn
        })
      } else {
        newData.push(val)
      }
    })
    const res = await ApiService.core.updateSetting(5, {
      data: newData
    })

    adminStore.setTranses(newData)

    message.success("Перевод успешно обновлен!")

    setIsDataLoading(prev => prev = false)
  }

  const addSocialAction = async () => {
    saveFooter((props) => {
      let newProps: any = {...props}
      newProps.socials = [...newProps.socials, {
        id: newProps.socials.length + 1,
        name: socialName,
        name_en: socialNameEn,
        url: socialUrl
      }]
      return newProps
    })
    setIsAddSocialVisible(false)
  }

  const saveSocialAction = async () => {
    saveFooter((props) => {
      let newProps: any = {...props}
      let newSocs: any[] = []
      newProps.socials.forEach((soc: any, ix: number) => {
        if (ix == socialSel) {
          newSocs.push({
            ...soc,
            name: socialName,
            name_en: socialNameEn,
            url: socialUrl
          })
        } else {
          newSocs.push(soc)
        }
      })
      newProps.socials = newSocs
      return newProps
    })
    setIsEdtSocialVisible(false)
  }

  const deleteSocialAction = async () => {
    saveFooter((props) => {
      let newProps: any = {...props}
      newProps.socials = newProps.socials.filter((v: any, ix: number) => ix != socialSel)
      return newProps
    })
    setIsDelSocialVisible(false)
  }

  const addLinkAction = async () => {
    saveFooter((props) => {
      let newProps: any = {...props}
      newProps.links = [...newProps.links, {
        id: newProps.links.length + 1,
        name: linkName,
        name_en: linkNameEn,
        url: linkUrl
      }]
      return newProps
    })
    setIsAddLinkVisible(false)
  }

  const saveLinkAction = async () => {
    saveFooter((props) => {
      let newProps: any = {...props}
      let newLnks: any[] = []
      newProps.links.forEach((lnk: any, ix: number) => {
        if (ix == linkSel) {
          newLnks.push({
            ...lnk,
            name: linkName,
            name_en: linkNameEn,
            url: linkUrl
          })
        } else {
          newLnks.push(lnk)
        }
      })
      newProps.links = newLnks
      return newProps
    })
    setIsEdtLinkVisible(false)
  }

  const deleteLinkAction = async () => {
    saveFooter((props) => {
      let newProps: any = {...props}
      newProps.links = newProps.links.filter((v: any, ix: number) => ix != linkSel)
      return newProps
    })
    setIsDelLinkVisible(false)
  }

  const fetchData = () => {
    setFtPhone(prev => prev = adminStore.footerData.phone)

    setFtAddress(prev => prev = adminStore.footerData.address)
    setFtAddressEn(prev => prev = adminStore.footerData.address_en)
    setFtAddressUrl(prev => prev = adminStore.footerData.address_url)

    setFtCopys(prev => prev = adminStore.footerData.inn)
    setFtCopysEn(prev => prev = adminStore.footerData.inn_en)
    setHdrVacs(prev => prev = adminStore.footerData.hdr_vacs)
    
    setFtSocials(adminStore.footerData.socials)
    setFtLinks(adminStore.footerData.links)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className={styles.manager_body__header}>
        <div className={styles.mbh_title}>
          <div>Панель Управления</div>
          <div>Проекты</div>
        </div>
        <div className={styles.mbh_close} onClick={() => closeManager()}><i className="fas fa-xmark"></i></div>
      </div>
      <div className={styles.manager_body__content}>
        <div className="container">
          <div className="row">

            <div className="col-12 col-md-12">
              <div style={{marginTop: "-4px", marginBottom: "30px"}} className={styles.xtabs + " " + styles.minimized + " " + styles[`active${activeTab + 1}`]}>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 0)}>Хедер</div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 1)}>Футер</div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 2)}>Пароль</div>
                <div className={styles.xtabs_item} onClick={() => setActiveTab(prev => prev = 3)}>Словарь</div>
              </div>
            </div>

            {activeTab == 3 ? <>
              <div className={"col-12 col-md-8 " + styles.mobbed} style={{marginBottom: "30px"}}>
                <div className={styles.grid_block}>
                  <h3>Перевод на языки</h3>
                  
                  <div className={styles.xforms}>
                    <div className={styles.xform_input__sw}>
                      <Switch defaultChecked={adminStore.transActive} id="trans_st" onChange={(event) => toggleTrans()} /> <span style={{marginLeft: "4px"}}>{adminStore.transActive ? "Включен" : "Выключен"}</span>
                    </div>
                  </div>

                  <div className={styles.xtable_wrap} style={{marginTop: "20px"}}>
                    <div className={styles.xtable}>
                      <div className={styles.xtable_head}>
                        <div>#</div>
                        <div>Наименование</div>
                        <div>Рус.</div>
                        <div>Англ.</div>
                        <div>Действие</div>
                      </div>
                      <div className={styles.xtable_body}>
                        {adminStore.transes.map((item: any, ix: number) => <div className={styles.xlist_item}>
                          <div className={styles.xlist_item__id}>{ix + 1}</div>
                          <div className={styles.xlist_item__name} onClick={() => {
                            selectTrans(item)
                          }}><span>{item.name}</span></div>
                          <div className={styles.xlist_item__pagename}>{item.value}</div>
                          <div className={styles.xlist_item__pagename}>{item.value_en}</div>
                          <div className={styles.xlist_item__actions}>
                            <button onClick={() => {
                              selectTrans(item)
                            }}><i className="far fa-pen-to-square"></i></button>
                          </div>
                        </div>)}
                      </div>
                    </div>
                  </div> 

                </div>
              </div>
            </> : <></>}

            {activeTab == 0 ? <>
              <div className={"col-12 col-md-12 " + styles.mobbed} style={{marginBottom: "30px"}}>
                <div className={styles.grid_block}>
                  <h3>Настройки хедера</h3>

                  <div className={styles.xform}>
                    <div className={styles.flexed}>
                      <div className={styles.xform_input}>
                        <label htmlFor="hdr_vacs">Ссылка на вакансии</label>
                        <input type="text" id="hdr_vacs" placeholder="https://hh.ru" value={hdrVacs} onChange={(event) => setHdrVacs(event.target.value)} />
                      </div>
                    </div>
                  
                    <button className={styles.xbtn} onClick={() => saveFooter()}>Сохранить</button>
                  
                  </div>
                </div>
              </div>
            </> : <></>}

            {activeTab == 1 ? <>
              <div className={"col-12 col-md-12 " + styles.mobbed} style={{marginBottom: "30px"}}>
                <div className={styles.grid_block}>
                  <h3>Настройки футера</h3>

                  <div className={styles.xform}>
                    <div className={styles.flexed}>
                      <div className={styles.xform_input}>
                        <label htmlFor="ft_copys">Копирайт</label>
                        <input type="text" id="ft_copys" placeholder="Копирайт" value={ftCopys} onChange={(event) => setFtCopys(event.target.value)} />
                      </div>
                      <div className={styles.xform_input}>
                        <label htmlFor="ft_phone">Телефон</label>
                        <input type="text" id="ft_phone" placeholder="Номер телефона" value={ftPhone} onChange={(event) => setFtPhone(event.target.value)} />
                      </div>
                    </div>

                    <div className={styles.xform_input}>
                      <label htmlFor="ft_addr">Адрес</label>
                      <input type="text" id="ft_addr" placeholder="Адрес" value={ftAddress} onChange={(event) => setFtAddress(event.target.value)} />
                    </div>
                    
                    {adminStore.transActive ? <>
                      <div className={styles.flexed}>
                        <div className={styles.xform_input}>
                          <label htmlFor="ft_addr_en">Адрес (англ.)</label>
                          <input type="text" id="ft_addr_en" placeholder="Адрес (англ.)" value={ftAddressEn} onChange={(event) => setFtAddressEn(event.target.value)} />
                        </div>
                        <div className={styles.xform_input}>
                          <label htmlFor="ft_copys_en">Копирайт (англ.)</label>
                          <input type="text" id="ft_copys_en" placeholder="Копирайт (англ.)" value={ftCopysEn} onChange={(event) => setFtCopysEn(event.target.value)} />
                        </div>
                      </div>
                    </> : <></>}
                  </div>
                
                  <button className={styles.xbtn} disabled={!isFooterChanged()} onClick={() => saveFooter()}>Сохранить</button>
                
                  <div className="row" style={{marginTop: "20px", paddingTop: "20px", borderTop: "1px solid #f1f1f1"}}>
                    <div className="col-12 col-md-6">
                      <div className={styles.setting_block}>
                        <h3>Соц. сети</h3>

                        <div className={styles.xactions} style={{top: "-10px"}}>
                          <div className={styles.xactions_item + " " + styles.success} onClick={() => {
                            setSocialName("")
                            setSocialNameEn("")
                            setSocialUrl("")
                            setIsAddSocialVisible(true)
                          }}>Добавить соц. сеть</div>
                        </div>

                        <div className={styles.xtable_wrap} style={{marginTop: "20px"}}>
                          <div className={styles.xtable}>
                            <div className={styles.xtable_head}>
                              <div>#</div>
                              <div style={{minWidth: "140px", width: "140px"}}>Наименование</div>
                              <div style={{minWidth: "200px"}}>URL</div>
                              <div>Действия</div>
                            </div>
                            <div className={styles.xtable_body}>
                              {ftSocials.map((item: IXSocial, ix) => <div className={styles.xlist_item}>
                                <div className={styles.xlist_item__id}>{ix + 1}</div>
                                <div className={styles.xlist_item__name} onClick={() => {
                                  setSocialName(item.name!)
                                  setSocialUrl(item.url!)
                                  setSocialSel(ix)
                                  setIsEdtSocialVisible(true)
                                }} style={{minWidth: "140px", width: "140px"}}><span>{item.name}</span></div>
                                <div className={styles.xlist_item__pagename} style={{minWidth: "200px", width: "100%"}}>{item.url}</div>
                                <div className={styles.xlist_item__actions}>
                                  <button onClick={() => {
                                    setSocialName(item.name!)
                                    setSocialUrl(item.url!)
                                    setSocialSel(ix)
                                    setIsEdtSocialVisible(true)
                                  }}><i className="far fa-pen-to-square"></i></button>
                                  <button onClick={() => {
                                    setSocialSel(ix)
                                    setIsDelSocialVisible(true)
                                  }}><i className="far fa-trash"></i></button>
                                </div>
                              </div>)}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className={styles.setting_block}>
                        <h3>Ссылки</h3>

                        <div className={styles.xactions} style={{top: "-10px"}}>
                          <div className={styles.xactions_item + " " + styles.success} onClick={() => {
                            setLinkName("")
                            setLinkNameEn("")
                            setLinkUrl("")
                            setIsAddLinkVisible(true)
                          }}>Добавить ссылку</div>
                        </div>

                        <div className={styles.xtable_wrap} style={{marginTop: "20px"}}>
                          <div className={styles.xtable}>
                            <div className={styles.xtable_head}>
                              <div>#</div>
                              <div style={{minWidth: "140px", width: "140px"}}>Наименование</div>
                              <div style={{minWidth: "200px"}}>URL</div>
                              <div>Действия</div>
                            </div>
                            <div className={styles.xtable_body}>
                              {ftLinks.map((item: IXSocial, ix) => <div className={styles.xlist_item}>
                                <div className={styles.xlist_item__id}>{ix + 1}</div>
                                <div className={styles.xlist_item__name} onClick={() => {
                                  setLinkName(item.name!)
                                  setLinkNameEn(item.name_en!)
                                  setLinkUrl(item.url!)
                                  setLinkSel(ix)
                                  setIsEdtLinkVisible(true)
                                }} style={{minWidth: "140px", width: "140px"}}><span>{item.name}</span></div>
                                <div className={styles.xlist_item__pagename} style={{minWidth: "200px"}}>{item.url}</div>
                                <div className={styles.xlist_item__actions}>
                                  <button onClick={() => {
                                    setLinkName(item.name!)
                                    setLinkNameEn(item.name_en!)
                                    setLinkUrl(item.url!)
                                    setLinkSel(ix)
                                    setIsEdtLinkVisible(true)
                                  }}><i className="far fa-pen-to-square"></i></button>
                                  <button onClick={() => {
                                    setLinkSel(ix)
                                    setIsDelLinkVisible(true)
                                  }}><i className="far fa-trash"></i></button>
                                </div>
                              </div>)}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </> : <></>}

            {activeTab == 2 ? <div className="col-12 col-md-4">
              <div className={styles.setting_block}>
                <h3>Сменить пароль</h3>
                  
                <div className={styles.xform}>
                  <div className={styles.xform_input}>
                    <label htmlFor="new_pwd">Новый пароль</label>
                    <input type="text" id="new_pwd" placeholder="Введите новый пароль" value={newPwd} onChange={(event) => setNewPwd(event.target.value)} />
                  </div>
                  <div className={styles.xform_input}>
                    <label htmlFor="new_pwd">Повторите новый пароль</label>
                    <input type="text" id="new_pwd" placeholder="Повторите новый пароль" value={newPwdRpt} onChange={(event) => setNewPwdRpt(event.target.value)} />
                  </div>
                </div>

                <button className={styles.xbtn} disabled={!isPwdFilled()} onClick={() => savePwd()}>Сохранить</button>
              </div>
            </div> : <></>}
          </div>
        </div>
      </div>

      <Modal
        visible={isAddSocialVisible || isEdtSocialVisible}
        onClose={() => {
          setIsAddSocialVisible(false)
          setIsEdtSocialVisible(false)
        }}
        title={`${isAddSocialVisible ? "Добавить" : "Изменить"} соц. сеть`}
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="tr_name">Наименование</label>
                <input type="text" id="tr_name" placeholder="Наименование соц. сети" value={socialName} onChange={(event) => setSocialName(prev => prev = event.target.value)} />
              </div>
              {adminStore.transActive ? <div className={styles.xform_input}>
                <label htmlFor="tr_name">Наименование (англ.)</label>
                <input type="text" id="tr_name" placeholder="Наименование соц. сети (англ.)" value={socialNameEn} onChange={(event) => setSocialNameEn(prev => prev = event.target.value)} />
              </div> : <></>}
              <div className={styles.xform_input}>
                <label htmlFor="tr_name">Ссылка</label>
                <input type="text" id="tr_name" placeholder="https://..." value={socialUrl} onChange={(event) => setSocialUrl(prev => prev = event.target.value)} />
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => isAddSocialVisible ? addSocialAction() : saveSocialAction()}>{isAddSocialVisible ? "Добавить" : "Сохранить"}</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isDelSocialVisible}
        title="Удалить соц. сеть"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить соц. сеть {adminStore.footerData.socials[socialSel].name}?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deleteSocialAction()} style={{marginBottom: "0px"}}>Удалить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsDelSocialVisible(false)} />

      <Modal
        visible={isAddSocialVisible || isEdtSocialVisible}
        onClose={() => {
          setIsAddSocialVisible(false)
          setIsEdtSocialVisible(false)
        }}
        title={`${isAddSocialVisible ? "Добавить" : "Изменить"} соц. сеть`}
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="tr_name">Наименование</label>
                <input type="text" id="tr_name" placeholder="Наименование соц. сети" value={socialName} onChange={(event) => setSocialName(prev => prev = event.target.value)} />
              </div>
              {adminStore.transActive ? <div className={styles.xform_input}>
                <label htmlFor="tr_name">Наименование (англ.)</label>
                <input type="text" id="tr_name" placeholder="Наименование соц. сети (англ.)" value={socialNameEn} onChange={(event) => setSocialNameEn(prev => prev = event.target.value)} />
              </div> : <></>}
              <div className={styles.xform_input}>
                <label htmlFor="tr_name">Ссылка</label>
                <input type="text" id="tr_name" placeholder="https://..." value={socialUrl} onChange={(event) => setSocialUrl(prev => prev = event.target.value)} />
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => isAddSocialVisible ? addSocialAction() : saveSocialAction()}>{isAddSocialVisible ? "Добавить" : "Сохранить"}</button>
            </div>
          </div>
        </>} />

      <Modal
        visible={isDelSocialVisible}
        title="Удалить соц. сеть"
        content={<>
          <div className="modal_text">
            <p>Вы действительно хотите удалить соц. сеть?</p>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => deleteSocialAction()} style={{marginBottom: "0px"}}>Удалить</button>
            </div>
          </div>
        </>}
        onClose={() => setIsDelSocialVisible(false)} />

        <Modal
          visible={isAddLinkVisible || isEdtLinkVisible}
          onClose={() => {
            setIsAddLinkVisible(false)
            setIsEdtLinkVisible(false)
          }}
          title={`${isAddLinkVisible ? "Добавить" : "Изменить"} ссылку`}
          content={<>
            <div className="modal_text">
              <div className={styles.xform}>
                <div className={styles.xform_input}>
                  <label htmlFor="tr_name">Наименование</label>
                  <input type="text" id="tr_name" placeholder="Наименование ссылки" value={linkName} onChange={(event) => setLinkName(prev => prev = event.target.value)} />
                </div>
                {adminStore.transActive ? <div className={styles.xform_input}>
                  <label htmlFor="tr_name">Наименование (англ.)</label>
                  <input type="text" id="tr_name" placeholder="Наименование ссылки (англ.)" value={linkNameEn} onChange={(event) => setLinkNameEn(prev => prev = event.target.value)} />
                </div> : <></>}
                <div className={styles.xform_input}>
                  <label htmlFor="tr_name">Ссылка</label>
                  <input type="text" id="tr_name" placeholder="https://..." value={linkUrl} onChange={(event) => setLinkUrl(prev => prev = event.target.value)} />
                </div>
              </div>
            </div>
            <div className="modal_action">
              <div>
                <button onClick={() => isAddLinkVisible ? addLinkAction() : saveLinkAction()}>{isAddLinkVisible ? "Добавить" : "Сохранить"}</button>
              </div>
            </div>
          </>} />
  
        <Modal
          visible={isDelLinkVisible}
          title="Удалить ссылку"
          content={<>
            <div className="modal_text">
              <p>Вы действительно хотите удалить ссылку?</p>
            </div>
            <div className="modal_action">
              <div>
                <button onClick={() => deleteLinkAction()} style={{marginBottom: "0px"}}>Удалить</button>
              </div>
            </div>
          </>}
          onClose={() => setIsDelLinkVisible(false)} />

      <Modal
        visible={isEditVisible}
        onClose={() => setIsEditVisible(false)}
        title="Изменить перевод"
        content={<>
          <div className="modal_text">
            <div className={styles.xform}>
              <div className={styles.xform_input}>
                <label htmlFor="tr_name">Наименование</label>
                <input type="text" id="tr_name" disabled={true} placeholder="Наименование перевода" value={transName} onChange={(event) => setTransName(prev => prev = event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="tr_name">Рус. перевод</label>
                <input type="text" id="tr_name" placeholder="Русский" value={transValue} onChange={(event) => setTransValue(prev => prev = event.target.value)} />
              </div>
              <div className={styles.xform_input}>
                <label htmlFor="tr_name">Англ. перевод</label>
                <input type="text" id="tr_name" placeholder="Английский" value={transValueEn} onChange={(event) => setTransValueEn(prev => prev = event.target.value)} />
              </div>
            </div>
          </div>
          <div className="modal_action">
            <div>
              <button onClick={() => saveTrans()}>Сохранить</button>
            </div>
          </div>
        </>} />
    </>
  )
}

export default observer(CoreManager)